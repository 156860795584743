import intialState from './initialState';
import * as types from '../actions/types';
import {Contracts} from "../../utils/contractApi";
import {TransactionMonitor} from "../../utils/transactionMonitor";

const widrawReducer = (state = intialState.withdraw, action) => {
  switch (action.type) {
    case types.WITHDRAW_REQUEST:
      return { ...state, loading: true, complete: false };

    case types.SET_WITHDRAW_TO_DEFAULT_STATE:
      return { ...state, loading: false, complete: false };

    case types.ADD_WITHDRAW_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        complete: false,
      };

    case types.ADD_WITHDRAW_TRANSACTION:
      const withdrawTransactionId = action.payload;
      return { ...state, withdrawTransactionId };

    case types.TRANSACTION_STATUS_CHANGED:
      const pending = Contracts.Token.transactionMonitor.pendingTransactions(TransactionMonitor.Topics.WITHDRAW).length > 0;
      const withdrawTxState = Contracts.Token.transactionMonitor.findTransactionById(TransactionMonitor.Topics.WITHDRAW, state.withdrawTransactionId);
      // Check if the stake tx is completed
      const complete = (withdrawTxState && withdrawTxState.lastState.state === TransactionMonitor.States.CONFIRMED);
      const failed = (withdrawTxState && withdrawTxState.lastState.state === TransactionMonitor.States.FAILED);
      const timedOut = (withdrawTxState && withdrawTxState.lastState.state === TransactionMonitor.States.TIMED_OUT);
      console.log('withdrawTxState TX STATUS', complete, failed, timedOut);
      let reason = withdrawTxState ? withdrawTxState.lastState.reason : '';
      reason = reason || (
        timedOut
          ? `Withdraw transaction timed out. Please check a block explorer to confirm: ${state.stakeTransactionId}` : ''
      ) || (
        failed ? `Withdraw transaction failed. Please check a block explorer to confirm: ${state.stakeTransactionId}` : '');
      return {
        ...state,
        variables: { ...state.variables, withdrawTxState },
        loading: pending,
        complete,
        error: reason,
      };

    default:
      return state;
  }
};

export default widrawReducer;
