import initialState from "./initialState";
import * as types from '../actions/types';

export const transactionListReducer = (state = initialState.transactionList, action) => {
    switch (action.type) {
        case types.TRANSACTION_LIST_UPDATED:
            const transactions = action.payload; // { [topic]: [List of txs] }
            const txs = Object.keys(transactions)
                .flatMap(k =>
                    transactions[k].map(ti => ({ ...ti.lastState, txId: ti.txId, topic: k })));
            txs.sort((a, b) => b.creationTime - a.creationTime);
            return {...state, items: txs};
        default:
            return state;
    }
};