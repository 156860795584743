import React, { useEffect } from 'react';
import M from 'materialize-css';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  minimumRewardMaturity,
  Timer,
  calculateReward,
  getDiffFormatted,
} from '../../utils/common';
import DoughnutChart from '../../common/DoughnutChart';

import dateFormatter from '../../utils/dateFormater';
import {AddressBox} from "./AddressBox";
import { TOKEN } from '../../utils/common';
import { DashboardBox } from './DashboardBox';

const WithdrawDashboard = ({
  link,
  stakeOf,
  balanceOf,
  stakingCap,
  contractAddress,
  deployedWithdrawEnd,
  owner,
  totalReward,
  deployedWithdrawStart,
  stakedTotal,
  deployedStakingStart,
  deployedStakingEnd,
  earlyWithdrawReward,
  bgColor,
  title,
}) => {

  return (
    <DashboardBox {...{
      link,
      stakeOf,
      stakingCap,
      totalReward,
      earlyWithdrawReward,
      stakedTotal,
      contractAddress,
      balanceOf,
      deployedStakingEnd,
      deployedStakingStart,
      deployedWithdrawStart,
      deployedWithdrawEnd,
      owner,
      bgColor,
      title,
    }}
      mode={'withdraw'}
    />
  );
  useEffect(() => {
    const elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems);
  }, []);

  const currentDate = Date.parse(new Date());

  const DoughnutChartPercent = ((currentDate - deployedWithdrawStart * 1000)
      / (deployedWithdrawEnd * 1000 - deployedWithdrawStart * 1000))
    * 100;

  const contractDate = deployedWithdrawEnd * 1000;

  return (
    <div className="min-component-height card-row black-text">
      <AddressBox title={title} bgColor={bgColor} owner={owner} contractAddress={contractAddress} />

      {currentDate < contractDate && (
      <div className=" row">
        <div className="col s12 m2" />
        <div className="col s12 m8">
          <div className={`center card ${bgColor}`}>
            <div className="card-content">
              <div className="row">
                <div className="col s12 m6">
                  <div className="center">
                    <div
                      className="center-content"
                      style={{ height: '15rem' }}
                    >
                      <div>
                        {getDiffFormatted(Timer(currentDate, contractDate))}
                        <pan className="review-title">Until Maturity</pan>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="col s12 m6">
                  <div>
                    <DoughnutChart
                      title="Time to Withdraw Maturity"
                      label1="Covered Time"
                      label2="Remaining Time"
                      remainingTime={100 - DoughnutChartPercent}
                      coveredTime={DoughnutChartPercent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}


      <div className="row">
        <div className="col s12 m2" />
        <div className="col s12 m8">
          <div className={`center card ${bgColor}`}>
            <div className="card-content">
              <NavLink to={link} className="btn btn-primary centered card-buttons">
                <i className="fab fa-gripfire" />
&nbsp;
Un Stake
              </NavLink>

              <div className="space-font section center-align black-text">
                <table>
                  <tbody>
                    <tr>
                      <td>Your Staked Balance:</td>
                      <td>{`${stakeOf.toFixed(2)} ${TOKEN}`}</td>
                    </tr>
                    <tr>
                      <td>Full Reward at Maturity:</td>
                      <td>
                        {`${
                          minimumRewardMaturity(
                            stakingCap,
                            totalReward,
                            deployedWithdrawEnd,
                            deployedStakingEnd,
                          )
                        }% up to ${totalReward} ${TOKEN} `}

                      </td>
                    </tr>
                    <tr>
                      <td>Rewards if unstaked today: </td>
                      <td>
                        <span className="card-badge">
                          {}
                        </span>

                      </td>
                    </tr>

                    <tr>
                      <td>Your Staked Balance:</td>

                      <td>
                        <span className="card-badge">
                          {`${stakeOf.toFixed(2)} ${TOKEN} `}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                          Early Withdraw Starts:
                      </td>
                      <td>

                        {dateFormatter(deployedWithdrawStart)}

                      </td>
                    </tr>

                    <tr>
                      <td>
                          Maturity At:
                      </td>
                      <td>
                        {dateFormatter(deployedWithdrawEnd)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
WithdrawDashboard.propTypes = {
  link: PropTypes.string.isRequired,
  stakeOf: PropTypes.number.isRequired,
  stakingCap: PropTypes.number.isRequired,
  contractAddress: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  deployedWithdrawEnd: PropTypes.number.isRequired,
  owner: PropTypes.string.isRequired,
  totalReward: PropTypes.number.isRequired,
  deployedWithdrawStart: PropTypes.number.isRequired,
  stakedTotal: PropTypes.number.isRequired,
  deployedStakingEnd: PropTypes.number.isRequired,
  earlyWithdrawReward: PropTypes.number.isRequired,
};
export default WithdrawDashboard;
