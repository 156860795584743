export default {
  reward: { loading: false },
  withdraw: {
    loading: false, complete: false,
    error: '',
    variables: { withdrawTxState: '' },
  },
  stake: {
    loading: false,
    complete: false,
    error: '',
    initialized: false,
    stakeTransactionId: '',
    variables: {
      pendingApprove: false,
      pendingStake: false,
      rewardBalance: 0,
      stakeOf: 0,
      balanceOf: 0,
      allowance: 0,
      stakedBalance: 0,
      complete: 0,
      stakingCap: 0,
      earlyWithdrawReward: 0,
      totalReward: 0,
      deployedStakingCap: Date.parse(new Date()) / 1000,
      deployedStakingStart: Date.parse(new Date()) / 1000,
      deployedStakingEnd: Date.parse(new Date()) / 1000,
      deployedWithdrawStart: Date.parse(new Date()) / 1000,
      deployedWithdrawEnd: Date.parse(new Date()) / 1000,
      profit: 0,
    },
  },
  contract: {
    loading: false,
    error: '',
    network: '',
    userAddress: '',
    values: {
      contractAddress: '',
      deployedStakingCap: 0,
      deployedStakingStart: Date.parse(new Date()) / 1000,
      deployedStakingEnd: Date.parse(new Date()) / 1000,
      deployedWithdrawStart: Date.parse(new Date()) / 1000,
      deployedWithdrawEnd: Date.parse(new Date()) / 1000,
    },
  },
  transactionList: {
    items: [], // [ { topic, txId, status }
  }
};
