import { connect } from 'react-redux';
import React from 'react';
import { TransactionMonitor } from '../../utils/transactionMonitor';

function shortenTxId(txId) {
  return `${txId.substr(0, 6)}...${txId.substr(txId.length - 6)}`;
}

function translateTopic(topic) {
  switch (topic) {
    case TransactionMonitor.Topics.APPROVE:
      return 'Apporove allocation';
    case TransactionMonitor.Topics.STAKE:
      return 'Stake';
    case TransactionMonitor.Topics.WITHDRAW:
      return 'Unstake';
    case TransactionMonitor.Topics.ADD_REWARD:
      return 'Add reward';
    default:
      return topic.toLocaleString();
  }
}

function TransactionList({ items }) {
  const txs = items;
  if (!txs.length) {
    return (<div />);
  }
  // TODO: Change the color of a row according to status
  return (
    <div className="container">
      <div className="row">
        <div className="col s12 m1" />
        <div className="col s12 m10"><h5>Your transactions so far</h5></div>
      </div>
      <div className="row">
        <div className="col s12 m1" />
        <div className="col s12 m10">
          <table className="centered">
            <thead>
              <tr>
                <td>Type</td>
                <td>Transaction ID</td>
                <td>Status</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {txs.map((tx, idx) => (
                <>
                  <tr key={idx}>
                    <td>{translateTopic(tx.topic)}</td>
                    <td><a target="_blank" rel="noopener noreferrer" href={`https://etherscan.io/tx/${tx.txId}`}>{shortenTxId(tx.txId)}</a></td>
                    <td>{tx.state.toLocaleLowerCase()}</td>
                    <td><a target="_blank" rel="noopener noreferrer" href={`https://etherscan.io/tx/${tx.txId}`}>View Transaction</a></td>
                  </tr>
                  {tx.reason ? (
                    <tr key={`${idx}_2`}>
                      <td colSpan={3}><span className="transaction-error">{tx.reason}</span></td>
                    </tr>
                  ) : undefined}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  items: state.transactionList.items,
});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionList);
