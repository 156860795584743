import { errorToast, successToast } from '../../utils/toasts';
import * as types from './types';
import {Contracts} from "../../utils/contractApi";

export const addRewardSuccessfull = (payload) => ({
  type: types.ADD_REWARD_SUCCESSFULL,
  payload,
});

export const addRewardRequest = () => ({
  type: types.ADD_REWARD_REQUEST,
});

export const addRewardFailure = (payload) => ({
  type: types.ADD_REWARD_FAILED,
  payload,
});

export const addRewardAction = (
  rewardAmount,
  withdrawableAmount,
  userAddress,
  data,
) => async (dispatch) => {
  const { contractAddress, } = data;
  try {
    dispatch(addRewardRequest());

    await Contracts.Token.approve(userAddress, contractAddress, rewardAmount);
    await Contracts.Festaking.addReward(userAddress, rewardAmount, withdrawableAmount);
    dispatch(addRewardSuccessfull('Success'));
    successToast('Reward Added Successfully');
  } catch (error) {
    dispatch(addRewardFailure(error));
    errorToast('Error adding a Reward');
  }
};
