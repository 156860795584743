import React from 'react';
import PropTypes from 'prop-types';

const ConnectContract = ({ onAllow, onReject }) => (
  <div id="modal2" className="modal black-text card-bg-platinum remove-border-outline ">
    <div className="modal-content center">
      <h5>Allow Staking dApp to connect to your crypto wallet</h5>
      <ul>
        <li>To detect and connect to the current network</li>
        <li>To get the active metamask account address</li>
      </ul>
      <p>
        <b>NB: </b>
Make sure you are connected to the &nbsp;
        <b>Main</b>
&nbsp;network
      </p>
    </div>
    <div className="modal-footer card-bg-platinum section space-btn">
      <button type="button" onClick={onReject} className="modal-close cancel-buttons btn">REJECT</button>
      <button type="button" onClick={onAllow} className="modal-close card-buttons btn">ALLOW</button>
    </div>
  </div>
);

ConnectContract.propTypes = {
  onAllow: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default ConnectContract;
