import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TOKEN } from '../utils/common';

const AddressDetails = ({
  contractAddress,
  currentAddress,
  message,
  amount,
}) => (
  <div className="row">
    <div className="col s12">
      <table className="">
        <tbody>
          <tr>
            <td>Contract Address:<br/>
            <NavLink to={`/dashboard/${contractAddress}`}>{contractAddress}</NavLink></td>
          </tr>
          <tr>
            <td>Current Wallet Address:<br/>
            {currentAddress}</td>
          </tr>
          <tr>
            <td>
              <table>
                <tbody>
              <tr>
                <td>{`Available to ${message}`}</td>
                <td>{`${amount.toFixed(4)} ${TOKEN} `}</td>
              </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);
AddressDetails.propTypes = {
  contractAddress: PropTypes.string,
  currentAddress: PropTypes.string,
  message: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

AddressDetails.defaultProps = {
  contractAddress: '',
  currentAddress: '',
};
export default AddressDetails;
