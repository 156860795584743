import React from 'react';
import PropTypes from 'prop-types';

const Term = ({ acceptTerms, checked, nextStep }) => (
  <div className="row">
    <div className="col s12 m2" />
    <div className="col s12 m8">
      <p className="text-justify">
        Disclaimer: Sending tokens to the wrong address or failure to properly
        follow instructions may result in lost tokens.
        PIE tokens are not &apos;stock&apos;, &apos;equity&apos;, &apos;shares&apos;,
        or any similar instrument in any jurisdiction. For more information on staking,
        please refer to &nbsp;
        <u>
          <a
            href="https://medium.com/defipie/defipie-staking-programme-details-cd49b024c118"
            target="_blank"
            rel="noopener noreferrer"
          >
this article
          </a>
        </u>
      </p>
      <form>
        <div>
          <input
            type="checkbox"
            className="filled-in mobile-friendly"
            onChange={acceptTerms}
            id="filled-in-box"
            checked={checked}
          />
          <label htmlFor="filled-in-box">I Agree</label>
        </div>
        <div className="right-align col s12 m2" />
        <div className="right-align col s12 m8">
          <button
            type="button"
            className="btn btn-primary stepper-buttons"
            onClick={nextStep}
            disabled={!checked}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  </div>
);

export default Term;
