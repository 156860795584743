import React from 'react';
import PropTypes from 'prop-types';
import formatDate from '../../utils/dateFormater';

const AddContractModel = ({
  name,
  contractAddress,
  stakingCap,
  stakingStart,
  stakingEnd,
  withdrawStart,
  withdrawEnd,
}) => (
  <div className="row">
    <div
      id="modal1"
      className="modal grey lighten-2 col s6 offset-s2 section"
      style={{ borderRadius: '5px', outline: 'none' }}
    >
      <div className="modal-content">
        <h5 className="center black-text"><strong>Contract Information</strong></h5>
        <div className="col s12">
          <div className="card default-bg">
            <div className="card-content white-text">
              <div className="row">
                <table className="col s10 offset-s1">
                  <tbody>
                    <tr>
                      <td>Contract Address </td>
                      <td>{contractAddress}</td>
                    </tr>
                    <tr>
                      <td>Contract Name </td>
                      <td>{name}</td>
                    </tr>
                    <tr>
                      <td>Staking Cap </td>
                      <td>{stakingCap.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Staking Start</td>
                      <td>{formatDate(stakingStart)}</td>
                    </tr>
                    <tr>
                      <td>Staking End</td>
                      <td>{formatDate(stakingEnd)}</td>
                    </tr>
                    <tr>
                      <td>Withdraw Start</td>
                      <td>{formatDate(withdrawStart)}</td>
                    </tr>
                    <tr>
                      <td>Withdraw End </td>
                      <td>{formatDate(withdrawEnd)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="modal-footer grey lighten-2 center-content">
        <button type="button" className="modal-close btn stepper-buttons section">Close</button>
      </div>
    </div>

  </div>
);
AddContractModel.propTypes = {
  contractAddress: PropTypes.string.isRequired,
  stakingCap: PropTypes.number.isRequired,
  stakingStart: PropTypes.number.isRequired,
  stakingEnd: PropTypes.number.isRequired,
  withdrawStart: PropTypes.number.isRequired,
  withdrawEnd: PropTypes.number.isRequired,
};
export default AddContractModel;
