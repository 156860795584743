import * as types from './types';
import { successToast, errorToast } from '../../utils/toasts';
import { vars } from './stake';
import { Contracts } from '../../utils/contractApi';

export const addWithdrawTransaction = (payload) => ({
  type: types.ADD_WITHDRAW_TRANSACTION,
  payload,
});

export const withdrawToDefaultState = () => ({
  type: types.SET_WITHDRAW_TO_DEFAULT_STATE,
});

export const withdrawRequest = () => ({
  type: types.WITHDRAW_REQUEST,
});

export const withdrawFailure = (payload) => ({
  type: types.ADD_WITHDRAW_FAILED,
  payload,
});


export const withdrawAction = (amount, data, userAddress) => async (dispatch) => {
  dispatch(withdrawRequest());
  try {
    const txId = await Contracts.Festaking.withdraw(userAddress, amount);
    await dispatch(vars({
      ...data, userAddress, amount, calculateProfit: true,
    }));
    await dispatch(addWithdrawTransaction(txId));
    successToast('Withdraw transaction submitted');
    document.getElementById('successModal').click();
  } catch (e) {
    console.error('Error withdraw', e);
    await dispatch(withdrawFailure(e));
    // document.getElementById('errorModal').click();
    errorToast('Error submitting the withdraw transaction');
  }
};
