import React from 'react';
import PropTypes from 'prop-types';
import StakeForm from '../../common/MultiStepForm';
import MultiStepCard from '../../common/MultiStepCard';
import Terms from '../../common/Terms';
import { StepDetails } from '../../common/StepDetails';
import SuccessBox from '../../common/SuccessBox';
import { TOKEN } from '../../utils/common';

const StakeStepper = ({
  amount,
  step,
  handleSubmit,
  handleChange,
  cancel,
  validateStake,
  authorizeStake,
  disable,
  acceptTerms,
  checked,
  nextStep,
}) => {
  switch (step) {
    case 0:
      return (
        <Terms acceptTerms={acceptTerms} checked={checked} nextStep={nextStep} />
      );
    case 1:
      return (
        <StakeForm
          nextStep={validateStake}
          handleChange={handleChange}
          amount={amount}
          disable={disable}
          title="Stake"
        />
      );
    case 2:
      return (
        <>
          <MultiStepCard
            cancel={cancel}
            amount={amount}
            nextStep={authorizeStake}
            buttonName="Approve and Stake"
            message="By pressing approve, we will generate two transactions. One to approve allocation for the staking contract, and second the transaction to execute the staking of"
            title="Approve Stake"
            disable={disable}
          />
          <StepDetails>
            You will be asked to approve two transactions. First transaction is to allow the staking contract
            to have access to {amount} of your {TOKEN} tokens. Second transaction will in fact execute the staking.
          </StepDetails>
        </>
      );
    case 3:
    case 4:
      return (
        <SuccessBox
          amount={amount}
        />
      );
    default:
      return (
        <StakeForm
          nextStep={validateStake}
          handleChange={handleChange}
          amount={amount}
          title="Stake"
          disable={disable}
        />

      );
  }
};

StakeStepper.propTypes = {
  amount: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  validateStake: PropTypes.func.isRequired,
  authorizeStake: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  acceptTerms: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default StakeStepper;
