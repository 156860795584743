import moment from 'moment';
import React from 'react';
import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DisplayTime from '../main/components/DisplayTime';

const annualize = (r, yearMs, dT) => (r * yearMs) / dT;

export const TOKEN = 'PIE';

export const earlyWithdrawAnnualRate = (
  stakedTotal,
  earlyWithdrawReward,
  withdrawEnd,
  stakingEnd,
) => {
  if (earlyWithdrawReward > 0) {
    const dT = withdrawEnd * 1000 - stakingEnd * 1000;
    const yearMs = 365 * 24 * 3600 * 1000;
    const annualRate = annualize(earlyWithdrawReward / stakedTotal, yearMs, dT);
    return annualRate;
  }
  return 0;
};

export const minimumRewardMaturity = (
  stakedTotal,
  totalReward,
  withdrawEnd,
  stakingEnd,
) => {
  if (totalReward > 0) {
    const dT = (withdrawEnd - stakingEnd) * 1000;
    const yearMs = 365 * 24 * 3600 * 1000;
    const annualRate = annualize(totalReward / stakedTotal, yearMs, dT);
    return annualRate;
  }
  return 0;
};

export const calculateReward = (
  amount,
  convertedDate,
  earlyWithdrawReward,
  totalReward,
  deployedWithdrawEnd,
  deployedStakingEnd,
  stakedTotal,
  percentEarlyUnstake,
) => {
  if (convertedDate > Number(deployedWithdrawEnd)) {
    const others = (stakedTotal - amount) * (1 - percentEarlyUnstake / 100);
    const remainingReward = totalReward - earlyWithdrawReward * (percentEarlyUnstake / 100) / 2; // Averaging early withdraws
    return amount / (amount + others) * remainingReward;
  }
  const earlyRate = earlyWithdrawAnnualRate(
    stakedTotal,
    earlyWithdrawReward,
    deployedWithdrawEnd,
    deployedStakingEnd,
  );
  const yearS = 365 * 24 * 3600;
  return earlyRate * (convertedDate - deployedStakingEnd) / yearS * amount;
};

export const Timer = (inputDate, outputDate) => {
  const inDate = moment(inputDate).unix();
  const outDate = moment(outputDate).unix();
  const diffTime = outDate - inDate;
  const duration = moment.duration(diffTime * 1000, 'milliseconds');

  if (diffTime > 0) {
    const days = moment.duration(duration).days();
    const hours = moment.duration(duration).hours();
    const minutes = moment.duration(duration).minutes();
    const seconds = moment.duration(duration).seconds();
    const months = moment.duration(duration).months();
    const years = moment.duration(duration).years();

    return {
      days,
      months,
      years,
      hours,
      minutes,
      seconds,
    };
  }

  return {
    days: 0,
    months: 0,
    years: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
};

export const getDiffFormatted = (d) => {
  const returnDate = (x, name) => ({ value: x, name });

  const years = returnDate(d.years, 'year(s)');
  const months = returnDate(d.months, 'month(s)');
  const days = returnDate(d.days, 'day(s)');
  const hours = returnDate(d.hours, 'hour(s)');
  const minutes = returnDate(d.minutes, 'minute(s)');
  const seconds = returnDate(d.seconds, 'second(s)');

  switch (true) {
    case d.years > 0:
      return <DisplayTime arg1={years} arg2={months.value ? months : days} />;
    case d.months > 0:
      return <DisplayTime arg1={months} arg2={days} />;
    case d.days > 0:
      return <DisplayTime arg1={days} arg2={hours} />;
    case d.hours > 0:
      return <DisplayTime arg1={hours} arg2={minutes} />;
    case d.minutes > 0:
      return <DisplayTime arg1={minutes} arg2={seconds} />;
    case d.seconds > 0:
      return <DisplayTime arg1={minutes} arg2={seconds} />;
    default:
      return (
        <DisplayTime arg1={null} arg2={null} />
      );
  }
};

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#3447b6',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3447b6',
    },
  },
  line: {
    borderColor: '#9e9e9e',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);


export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: 'white',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  completed: {
    borderColor: '#784af4',
    color: 'pink',
  },
  active: {
    color: 'green',
  },
  new: {
    color: 'grey',
    '& $completed': {
      color: '#3347b6',
    },
    '& $active': {
      color: '#3347b6',
    },
    '& $disabled': {
      color: 'red',
    },
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0,
      color: '#000000',
    },
  },
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    color: '#ffffff',
  },
}));

export const setTheme = (contractAddress) => {
  switch ((contractAddress || '').toLowerCase()) {
    case '0x09e174d3958f2948c855edc9256b0a4cddf6534b':
      return { bgColor: 'card-bg-platinum', title: 'Platinium' };
    case '0xa45c5523871318ac26a550918ae7620eb6e7f694':
      return { bgColor: 'card-bg-golden', title: 'Gold' };
    case '0x45972d73d35a315c0d8357846303209991b84ccb':
      return { bgColor: 'card-bg-platinum', title: 'Rhodium' };
    case '0xeee410b42bc3a71271f191579858cf7e3cbb2e70':
      return { bgColor: 'card-bg-promethium', title: 'Promethium' };
    case '0xbd5c104ad40ef137d6810e6ccc0b2b7185410374':
      return { bgColor: 'card-bg-palladium', title: 'Palladium' };
    case '0x143d7ee3fab601264248c2c3f45be430451e353f':
      return { bgColor: 'card-bg-titanium', title: 'Titanium' };
    default:
      return { bgColor: 'card-bg-diamond', title: 'Diamond' };
  }
};
