import intialState from './initialState';
import * as types from '../actions/types';
import { TransactionMonitor } from '../../utils/transactionMonitor';
import { Contracts } from '../../utils/contractApi';
import { updateAllowance } from '../actions/stake';
import { AsyncLoop } from '../actions/asyncLoop';

const deployContractReducer = (state = intialState.contract, action) => {
  switch (action.type) {
    case types.DEPLOY_CONTRACT_FAILED:
      return { ...state, error: action.payload, loading: false };

    case types.DEPLOY_CONTRACT_SUCCESSFUL:
      return { ...state, loading: false };

    case types.ADD_USER_AND_CONTRACT_ADDRESS:
      const { contractAddress, userAddress } = action.payload;
      return {
        ...state, userAddress, values: { ...state.values, contractAddress },
      };

    case types.DEPLOY_CONTRACT_LOADING:
      return {
        ...state, loading: action.payload, error: null,
      };

    case types.DEPLOYMENT_VALUES_SUCCESS:
      return { ...state, values: action.payload, loading: false };

    case types.DEPLOYMENT_VALUES_ERROR:
      return {
        ...state, error: action.payload, loading: false,
      };

    case types.DISPATCH_NETWORK_TYPE:
      return { ...state, network: action.payload };

    case types.TRANSACTION_STATUS_CHANGED:
      const { topic } = action.payload;
      if (topic === TransactionMonitor.Topics.APPROVE) {
        Contracts.Token.allowance(state.userAddress, state.values.contractAddress).then((allowance) => {
          AsyncLoop.instance.push(updateAllowance(allowance));
        });
      }
      return state;

    default:
      return state;
  }
};

export default deployContractReducer;
