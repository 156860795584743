import React from 'react';
import { NavLink } from 'react-router-dom';

const DetectMetamask = () => (
  <>
    <div data-target="detectMetamask" id="metamaskModal" className="modal-trigger" />

    <div id="detectMetamask" className="modal card-bg-platinum remove-border-outline">
      <div className="modal-content black-text">
        <p>Non-Ethereum browser detected, please use google chrome and install Metamask plugin to interact with this application. Then refresh the page to continue.</p>
      </div>
      <div className="modal-footer card-bg-platinum">
        <NavLink to="/" className="modal-close card-buttons btn">
            Close
        </NavLink>
      </div>
    </div>
  </>
);
export default DetectMetamask;
