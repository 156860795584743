import React from "react";
import { Link } from "react-router-dom";
import '../../assets/css/home.scss';
import BackgroundLeft from '../../assets/images/bkg-left.png';
import { TOKEN } from '../../utils/common';
import {
  Timer,
  minimumRewardMaturity,
  earlyWithdrawAnnualRate,
  getDiffFormatted,
  calculateReward,
} from '../../utils/common';
import dateFormatter from '../../utils/dateFormater';

const convertedDate = Date.parse(new Date()) / 1000;

function Title({title, icon}) {
  return (
    <div class="contract-title">
      <div class="contract-title-logo">
        <img src={icon} class="contract-logo" />
      </div>
      <div class="contract-title-heading">
        <span class="title">{title}</span>
        <span class="subtitle">STAKING PACKAGE</span>
      </div>
    </div>
  );
}

export function LabelText({label, text, link}) {
  return (
    <>
    <div class="box-label">
      <span>{label}</span>
    </div>
    <div class="box-value">
      <a href={link} target="_blank" rel="noopener noreferrer">{text}</a>
    </div>
    </>
  );
}

function shorten(addr) {
  return `${addr.substr(0, 10)}...${addr.substr(addr.length - 10)}`
}

function LinkButton({title, url}) {
  return (
    <Link class="link-btn" to={url}><span>{title}</span></Link>
  );
}

export function AddressBox({title, icon, bgColor, owner, contractAddress, buttons}) {
  return (
      <div className="col s12 m6">
        <div className={`card ${bgColor} section address-box`}
            style={{backgroundImage: `url(${BackgroundLeft})`}}>
          <div className="card-content">
            <Title title={title} icon={icon} />
            <LabelText label={'Your address:'} text={shorten(owner)} link={`https://etherscan.io/address/${owner}`} />
            <LabelText label={'Contract address:'} text={shorten(contractAddress)} link={`https://etherscan.io/address/${contractAddress}`} />
            <div class="gap"></div>
            <div class="btn-container">
              {(buttons || []).map((b, i) => (<LinkButton key={i} {...b} />))}
            </div>
          </div>
        </div>
      </div>
  );
}

export function InfoBox({stakeOf, stakingCap, stakedTotal,
    totalReward, deployedStakingStart, deployedStakingEnd,
    deployedWithdrawStart, deployedWithdrawEnd,
    earlyWithdrawReward,
    mode,
  }) {
  const rewardIfUnstakeToday = mode === 'withdraw' ? (
    <LabelValueInLine label={'Rewards Today'}>
      <TextWithSuffix text={`${calculateReward(
                            stakeOf,
                            convertedDate,
                            earlyWithdrawReward,
                            totalReward,
                            deployedWithdrawEnd,
                            deployedStakingEnd,
                            stakedTotal,
                            0,
                          ).toFixed(2)}`} suffix={TOKEN} />
    </LabelValueInLine>
  ) : undefined;

  const stakingStart = mode === 'stake' ? (
    <LabelValueInLine label={'Staking Starts'}>
      <TextGray text={dateFormatter(deployedStakingStart)} />
    </LabelValueInLine>
  ) : undefined;

  const stakingEnd = mode === 'stake' ? (
    <LabelValueInLine label={'Staking Ends'}>
      <TextGray text={dateFormatter(deployedStakingEnd)} />
    </LabelValueInLine>
  ) : undefined;

  const stakedSoFar = mode === 'stake' ? (
    <LabelValueInLine label={'Staked so Far'}>
      <TextWithSuffix text={(stakedTotal || 0).toFixed(2)} suffix={TOKEN} />
    </LabelValueInLine>
  ) : undefined;

  return (
    <>
      <div className="col s12 m6">
        <LabelValueInLine label={'Your Staked Balance'}>
          <TextWithSuffix text={stakeOf.toFixed(2)} suffix={TOKEN} />
        </LabelValueInLine>
        <LabelValueInLine label={'Staking Cap'}>
          <TextWithSuffix text={stakingCap.toFixed(2)} suffix={TOKEN} />
        </LabelValueInLine>
        {stakedSoFar}
        {rewardIfUnstakeToday}
        <LabelValueInLine label={'Reward at Maturity'}>
          <TextWithSuffix text={`${
                              100 * minimumRewardMaturity(
                                stakingCap,
                                totalReward,
                                deployedWithdrawEnd,
                                deployedStakingEnd,
                              ).toFixed(2)
                            } % upto ${totalReward}`} suffix={TOKEN} />
        </LabelValueInLine>
        <LabelValueInLine label={'Early Reward'}>
          <TextNoSuffix text={`${
                              100 * earlyWithdrawAnnualRate(
                                stakingCap,
                                earlyWithdrawReward,
                                deployedWithdrawEnd,
                                deployedStakingEnd,
                            ).toFixed(2)} % p.a.`} />
        </LabelValueInLine>
        {stakingStart}
        {stakingEnd}
        <LabelValueInLine label={'Early Withdraw Starts'}>
          <TextGray text={dateFormatter(deployedWithdrawStart)} />
        </LabelValueInLine>
        <LabelValueInLine label={'Maturity at'}>
          <TextGray text={dateFormatter(deployedWithdrawEnd)} />
        </LabelValueInLine>
        <div class="gap" ></div>
      </div>
    </>
  );
}

function LabelValueInLine({label, children}) {
  return (
    <div class="right-row">
      <span>{label}</span>
      {children}
    </div>
  );
}

function TextGray({text}) {
  return (
    <div class="text-with-suffix">
      <span class="text-with-suffix-grey">{text}</span>
    </div>
  );
}

function TextNoSuffix({text}) {
  return (
    <div class="text-with-suffix">
      <span class="text-with-suffix-text">{text}</span>
    </div>
  );
}

function TextWithSuffix({text, suffix}) {
  return (
    <div class="text-with-suffix">
      <span class="text-with-suffix-text">{text}</span> &nbsp;&nbsp;
      <span class="text-with-suffix-suffix">{suffix}</span>
    </div>
  );
}
