/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import PropTypes from 'prop-types';
import { connectContractAction } from '../../redux/actions/deploy';
import formatDate from '../../utils/dateFormater';
import StakeDashboard from '../components/StakeDashboard';
import WithdrawDashboard from '../components/WithdrawDashboard';
import ConnectContract from '../components/ConnectContract';
import Notification from '../components/Notification';
import { setTheme } from '../../utils/common';
import Error from '../../common/Error';
import HomeButton from '../../common/HomeButton';
import { TOKEN } from '../../utils/common';

class Dashboard extends React.Component {
  state = {
    withdraw: false,
    stake: false,
    check: false,
  };

  async componentDidMount() {
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      inDuration: 500, outDuration: 100, opacity: 0.6, dismissible: false,
    });
    try {
      const connectToMetamask = localStorage.getItem('connectToMetamask');

      if (connectToMetamask) {
        const {
          connectContractActionFunc,
          match: {
            params: { address },
          },
        } = this.props;

        this.setState({ address });
        await connectContractActionFunc(address);
        this.disableActions();
      } else {
        document.getElementById('contractModal').click();
      }
    } catch (error) {
      this.setState({ check: true });
      document.getElementById('metamaskModal').click();
    }
  }

  onAllow = async () => {
    const {
      connectContractActionFunc,
      match: {
        params: { address },
      },
    } = this.props;
    await connectContractActionFunc(address);
    localStorage.setItem('connectToMetamask', true);
    this.disableActions();
  };

  onReject = () => {
    const {
      history: { push },
    } = this.props;
    push('/');
  };

  disableActions = () => {
    const {
      stake: {
        variables: {
          deployedWithdrawStart,
          deployedStakingStart,
          deployedStakingEnd,
        },
      },
    } = this.props;
    const currentDate = new Date();
    const now = currentDate.getTime() / 1000;

    const staketime = now > deployedStakingStart && now <= deployedStakingEnd;
    const withdrawtime = now >= deployedWithdrawStart;
    this.setState({ withdraw: withdrawtime, stake: staketime });
  };

  render() {
    const {
      contract: {
        error, values: data, network, loading, userAddress,
      },
      stake: {
        initialized,
        variables: {
          balanceOf,
          stakedBalance,
          stakeOf,
          earlyWithdrawReward,
          totalReward,
          deployedWithdrawStart,
          deployedWithdrawEnd,
          deployedStakingStart,
          deployedStakingEnd,
          stakingCap,
          stakedTotal,
        },
      },
    } = this.props;
    const {
      withdraw, stake, check, address,
    } = this.state;
    const theme = setTheme(address);
    return (
      <div className="">

        {<Notification network={network} />}
        {error ? (
          <Error message={error} />
        ) : (
          <div className="  ">
            <ConnectContract onAllow={this.onAllow} onReject={this.onReject} />
            <div
              id="contractModal"
              className="waves-effect waves-light modal-trigger"
              href="#modal2"
            />
            {stake && (
              <StakeDashboard
                link={`/${data && data.contractAddress}/stake`}
                stakeOf={stakeOf}
                stakingCap={stakingCap}
                stakedBalance={stakedBalance}
                balanceOf={balanceOf}
                totalReward={totalReward}
                deployedStakingEnd={deployedStakingEnd}
                earlyWithdrawReward={earlyWithdrawReward}
                deployedStakingStart={deployedStakingStart}
                deployedWithdrawEnd={deployedWithdrawEnd}
                deployedWithdrawStart={deployedWithdrawStart}
                contractAddress={data && data.contractAddress}
                stakedTotal={stakedTotal}
                owner={userAddress}
                bgColor={theme.bgColor}
                title={theme.title}
              />
            )}
            {withdraw && (
              <WithdrawDashboard
                link={`/${data && data.contractAddress}/withdraw`}
                stakeOf={stakeOf}
                stakingCap={stakingCap}
                stakedBalance={stakedBalance}
                deployedWithdrawEnd={deployedWithdrawEnd}
                contractAddress={data && data.contractAddress}
                deployedWithdrawStart={deployedWithdrawStart}
                deployedStakingStart={deployedStakingStart}
                deployedStakingEnd={deployedStakingEnd}
                owner={userAddress}
                totalReward={totalReward}
                stakedTotal={stakedTotal}
                earlyWithdrawReward={earlyWithdrawReward}
                bgColor={theme.bgColor}
                title={theme.title}
              />
            )}
            {!withdraw
              && !stake
              && (loading || !initialized ? (
                <div />
              ) : (
                (check ? <div className="min-component-height" /> : (
                  <div className="row">
                    <div className="col s12 m2" />
                    <div className="col s12 m8">
                      <div className={`card ${theme.bgColor}`}>
                        <div className="card-content black-text">
                          <span className="card-title center"><strong>You can&apos;t Stake Now</strong></span>
                          <p className="section">
                          Every contract has an allocated time within which
                          users can stake to it. Staking period is
                            between
                            {' '}
                            {new Date(deployedStakingStart * 1000).toLocaleDateString()}
                            {' '}
and
                            {' '}
                            {
                              new Date(deployedStakingEnd * 1000).toLocaleDateString()
}
                          </p>
                          {deployedStakingStart * 1000 > Date.now() ? (
                            <p className="center">
                                Staking Starts at: &nbsp;
                              <b className="" style={{ fontSize: '18px' }}>
                                {formatDate(deployedStakingStart)}
                              </b>
                            </p>
                          ) : deployedStakingEnd * 1000 < Date.now() ? (
                            <>
                              <p className="center">
                                  Staking ended at: &nbsp;
                                <b className="" style={{ fontSize: '18px' }}>
                                  {formatDate(deployedStakingEnd)}
                                </b>
                              </p>
                              <p className="center">
                                Early withdrawal will start at: &nbsp;
                                <b className="" style={{ fontSize: '18px' }}>
                                  {formatDate(deployedWithdrawStart)}
                                </b>
                              </p>
                              <p className="center">
                                Withdrawal dashboard will become enabled afterwards.
                              </p>
                              <p className="center">
                                Your stake balance is: <b>{stakeOf} {TOKEN}</b>
                              </p>
                            </>
                          ) : (
                            <p className="center">
                                please wait ...
                            </p>
                          )}


                        </div>
                        <div className="card-action center">
                          <HomeButton />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ contract, stake }) => ({
  contract,
  stake,
});

const mapDispatchToProps = {
  connectContractActionFunc: connectContractAction,
};

Dashboard.propTypes = {
  connectContractActionFunc: PropTypes.func.isRequired,
  stake: PropTypes.shape({
    variables: PropTypes.shape({
      balanceOf: PropTypes.number.isRequired,
      stakedBalance: PropTypes.number.isRequired,
      stakeOf: PropTypes.number.isRequired,
      earlyWithdrawReward: PropTypes.number.isRequired,
      totalReward: PropTypes.number.isRequired,
      deployedWithdrawStart: PropTypes.number.isRequired,
      deployedWithdrawEnd: PropTypes.number.isRequired,
      deployedStakingStart: PropTypes.number.isRequired,
      deployedStakingEnd: PropTypes.number.isRequired,
      stakingCap: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      address: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  contract: PropTypes.shape({
    error: PropTypes.string,
    values: PropTypes.shape({}),
    network: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
