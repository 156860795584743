import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ complete }) => (
  <div>
    <div className=" overall-color custom-loader">
      <div className={`circle-loader ${complete && 'load-complete'}`}>
        <div className={`${complete && 'checkmark draw'}`} />
      </div>
    </div>
    {!complete && (<span className="center review-title">Waiting for the transaction to complete..</span>)}
  </div>
);

Loader.propTypes = {
  complete: PropTypes.bool.isRequired,
};

export default Loader;
