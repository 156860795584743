import React, { useEffect } from 'react';

import {AddressBox, InfoBox} from "./AddressBox";
import BackgroundWide from '../../assets/images/bkg-wide.png';
import Diamond from '../../assets/images/ruby.png';
import Gold from '../../assets/images/gold.png';
import Platinium from '../../assets/images/platinium.png';
import moment from 'moment';

const ICON_MAP = {
  'Diamond': Diamond,
  'Gold': Gold,
  'Platinium': Platinium,
}

function tillDate(date) {
  var endDate = new Date(date * 1000);
  var now = new Date();

  var m = moment(endDate);
  const d2 = moment(now);
  var years = m.diff(d2, 'years');
  m.add(-years, 'years');
  var months = m.diff(d2, 'months');
  m.add(-months, 'months');
  var days = m.diff(d2, 'days');
  m.add(-days, 'days');
  var hours = m.diff(d2, 'hours');

  return [years*12 + months,
    days, hours];
}

function MainProgress({max, position, children}) {
  return (
    <div class="main-progress">
      {children}
    </div>
  )
}


export function DashboardBox({
  link,
  stakeOf,
  stakingCap,
  totalReward,
  earlyWithdrawReward,
  stakedTotal,
  contractAddress,
  balanceOf,
  deployedStakingEnd,
  deployedStakingStart,
  deployedWithdrawStart,
  deployedWithdrawEnd,
  owner,
  bgColor,
  title,
  mode,
}) {
  const [tillMon, tillDay, tillHour] = tillDate(deployedWithdrawEnd);
  const buttons = mode === 'stake' ? (
        [ {title: 'Stake', url: link} ,
          { title: 'Calculator', url: `/reward-calculator/${contractAddress}` }]
        ) : (
        [ {title: 'Un stake', url: link} ]
  );

  return (
    <>
    <div className="row black-text ">
    <div class="col s0 m0 l1" > </div>
    <div class="col s12 m12 l10"
      style={{backgroundImage: `url(${BackgroundWide})`}} >
      <AddressBox
        title={title} icon={ICON_MAP[title] || Diamond} bgColor={bgColor} owner={owner} contractAddress={contractAddress}
        buttons={buttons}
      />
      <InfoBox
        stakeOf={stakeOf}
        stakingCap={stakingCap}
        stakedTotal={stakedTotal}
        totalReward={totalReward}
        deployedStakingStart={deployedStakingStart}
        deployedStakingEnd={deployedStakingEnd}
        deployedWithdrawStart={deployedWithdrawStart}
        deployedWithdrawEnd={deployedWithdrawEnd}
        earlyWithdrawReward={earlyWithdrawReward}
        mode={mode}
      />
      </div>
      </div>
    <div className="row black-text ">
    <div class="col s0 m0 l1" > </div>
      <div class="col s12 m12 l10" >
        <MainProgress max={1} position={Math.min(1, (Date.now() - deployedStakingStart) / (deployedWithdrawEnd - deployedStakingStart)) }>
          <div class="main-progress-text-1"><span>MATURITY TIMER</span></div>
          <div class="main-progress-text-2"><span><b>{tillMon}</b> MONTHS</span></div>
          <div class="main-progress-text-2"><span><b>{tillDay}</b> DAYS</span></div>
          <div class="main-progress-text-2"><span><b>{tillHour}</b> HOURS</span></div>
        </MainProgress>
        </div>
    </div>
    </>
  );
}