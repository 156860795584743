import React from 'react';
import HomeButton from './HomeButton';

const Error = ({message}) => (
  <div className="modal-content center no-bottom-margin">
    <div>
      <h5 className="white-text">Oops! An Error Occured!</h5>
      <h5 className="white-text">{message.toString()}</h5>
      <h6 className="white-text">Try again, if the error persits contact our Support Team</h6>
      <br />
      <HomeButton />
    </div>
  </div>
);

const Error_ = ({message}) => (
  <div
    id="modal4"
    className="modal default-bg-extended remove-border-outline"
    style={{ border: 'white solid 2px' }}
  >
    <div className="modal-content center no-bottom-margin">
      <div>
        <h5 className="white-text">Oops! An Error Occured!</h5>
        <h6 className="white-text">Try again, if the error persits contact our Support Team</h6>
        <br />
        <HomeButton />
      </div>
    </div>
  </div>
);
export default Error;
