const formatDate = (dateSec) => {
  const dateMilli = dateSec * 1000;
  const date = new Date(dateMilli).toLocaleString();
  return date === 'Invalid Date' ? 'Loading ...' : date;
};

export const formFormatter = (dateMilli) => {
  return new Date(dateMilli).toISOString().substr(0, 16);
};

export default formatDate;
