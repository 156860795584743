import intialState from './initialState';
import * as types from '../actions/types';
import { TransactionMonitor } from '../../utils/transactionMonitor';
import { Contracts } from '../../utils/contractApi';

const stakeReducer = (state = intialState.stake, action) => {
  switch (action.type) {
    case types.ADD_STAKE_REQUEST:
      return { ...state, loading: true, complete: false };

    case types.ADD_STAKE_SUCCESSFULL:
      return {
        ...state, loading: false, complete: true,
      };

    case types.ADD_STAKE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        complete: false,
      };

    case types.APPROVE_STAKE_SUCCESSFULL:
      return { ...state, loading: false };

    case types.APPROVE_STAKE_FAILED:
      return {
        ...state,
        loading: false,
        complete: false,
      };

    case types.UPDATE_STAKE_VARIABLES:
      return { ...state, variables: action.payload, initialized: true };

    case types.TRANSACTION_STATUS_CHANGED:
      const pendingApprove = Contracts.Token.transactionMonitor.pendingTransactions(TransactionMonitor.Topics.APPROVE).length > 0;
      const pendingStake = Contracts.Token.transactionMonitor.pendingTransactions(TransactionMonitor.Topics.STAKE).length > 0;
      const pending = pendingStake || pendingApprove;
      const stakeTxState = Contracts.Token.transactionMonitor.findTransactionById(TransactionMonitor.Topics.STAKE, state.stakeTransactionId);
      // Check if the stake tx is completed
      const complete = (stakeTxState && stakeTxState.lastState.state === TransactionMonitor.States.CONFIRMED);
      const failed = (stakeTxState && stakeTxState.lastState.state === TransactionMonitor.States.FAILED);
      const timedOut = (stakeTxState && stakeTxState.lastState.state === TransactionMonitor.States.TIMED_OUT);
      console.log('STAKE TX STATUS', complete, failed, timedOut);
      let reason = stakeTxState ? stakeTxState.lastState.reason : '';
      reason = reason || (
        timedOut
          ? `Stake transaction timed out. Please check a block explorer to confirm: ${state.stakeTransactionId}` : ''
      ) || (
        failed ? `Stake transaction failed. Please check a block explorer to confirm: ${state.stakeTransactionId}` : '');
      return {
        ...state,
        variables: { ...state.variables, pendingStake, pendingApprove },
        loading: pending,
        complete,
        error: reason,
      };

    case types.UPDATE_ALLOWANCE:
      const allowance = action.payload;
      return { ...state, variables: { ...state.variables, allowance } };

    case types.ADD_STAKE_TRANSACTION:
      const stakeTransactionId = action.payload;
      return { ...state, stakeTransactionId };

    default:
      return state;
  }
};

export default stakeReducer;
