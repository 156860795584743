/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StepperUi from '../components/StepperStake';
import { connectContractAction } from '../../redux/actions/deploy';
import {
  authorizeAddStake,
  vars,
} from '../../redux/actions/stake';
import { errorToast } from '../../utils/toasts';
import Error from "../../common/Error";
import TransactionList from "./TransactionList";
import SuccessBox from '../../common/SuccessBox';
import M from 'materialize-css';
import { TOKEN } from '../../utils/common';


const MIN_STAKE = 5000;

export class Stake extends Component {
  state = {
    amount: '',
    step: 0,
    disable: false,
    activeStep: 0,
    checked: false,
  };

  async componentDidMount() {
    const {
      connectContractActionFunc,
      contract,
      match: {
        params: { address },
      },
    } = this.props;
    if (!contract.data) {
      await connectContractActionFunc(address);
    }
    const {
      contract: { error },
    } = this.props;
    !error
      && (async () => {
        // await this.continueWithApprove();
      })();

    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      inDuration: 500,
      outDuration: 500,
      opacity: 0.8,
      dismissible: false,
      endingTop: '15%',
    });
  }

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  handleReset = () => {
    this.setState({
      amount: 0,
      step: 0,
      activeStep: 0,
      checked: false,
    });
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  resetStep = () => {
    this.setState({ amount: 0, step: 1 });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      stake,
    } = this.props;
    const { error, complete, } = stake;
    if (complete) {
      document.getElementById('successModal').click();
    }
  }

  validateStake = async () => {
    const {
      stake: {
        variables: { rewardBalance, stakedTotal, stakingCap, balanceOf, stakingEnd, },
      },
    } = this.props;
    const { amount } = this.state;

    if (amount <= 0) {
      errorToast('Please enter a value above zero');
      return;
    }
    if (parseInt(amount, 10) + parseInt(stakedTotal, 10) > stakingCap) {
      errorToast(
        `Can't stake above the staking cap, Can only stake ${stakingCap
          - stakedTotal} ${TOKEN}`,
      );
      return;
    }

    if (parseFloat(amount) > balanceOf) {
      errorToast(
          `You don't have enough ${TOKEN} to stake ${amount} `,
      );
      return;
    }

    if (Date.now() > stakingEnd) {
      errorToast(
        `We are passed staking period`,
      );
      return;
    }

    // if (rewardBalance <= 0) {
    //   errorToast("Can't stake, reward hasn't been set yet");
    //   return;
    // }
    this.handleNext();
  };

  authorizeStake = async (event) => {
    event.preventDefault();
    const {
      contract: {  userAddress, values: data },
      authorizeAddStakeFunc,
    } = this.props;
    const { amount } = this.state;
    try {
      await authorizeAddStakeFunc(amount, data, userAddress);
    } catch (error) {
      this.handleReset();
    }
  };

  acceptTerms = (event) => {
    const { checked } = event.target;
    if (checked) {
      this.setState({ checked: true });
    } else {
      this.setState({ checked: false });
    }
  };

  render() {
    let {
      amount, step, activeStep, checked, disable,
    } = this.state;
    const {
      contract: { values: data, userAddress },
      stake,
    } = this.props;
    const {variables: {allowance, pendingStake, pendingApprove},
        error, complete, } = stake;
    if (activeStep < 2 && (allowance > 0 || pendingApprove)) {
      activeStep = 2;
    }
    if (activeStep < 2 && pendingStake) {
      activeStep = 2;
    }
    if (complete) {
      activeStep = 3;
    }

    return (
      <div>
        {error ? (
          <Error message={error} />
        ) : (
          <>
            <div data-target="modal4" id="errorModal" className="modal-trigger" />
            <div data-target="modal3" id="successModal" className="modal-trigger" />
            <SuccessBox
              amount={amount}
            />
            <StepperUi
              amount={amount || allowance || ''}
              step={step}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              cancel={this.handleReset}
              validateStake={this.validateStake}
              authorizeStake={this.authorizeStake}
              disable={disable}
              error={stake.error}
              activeStep={activeStep}
              handleReset={this.handleReset}
              handleBack={this.handleBack}
              handleNext={this.handleNext}
              loading={stake.loading || !stake.initialized}
              complete={complete}
              acceptTerms={this.acceptTerms}
              checked={checked}
              stake={stake}
              data={data}
              userAddress={userAddress}
            />
            <TransactionList />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ contract, stake }) => ({
  contract,
  stake,
});

const mapDispatchToProps = {
  connectContractActionFunc: connectContractAction,
  authorizeAddStakeFunc: authorizeAddStake,
  varsFunc: vars,
};

Stake.propTypes = {
  authorizeAddStakeFunc: PropTypes.func.isRequired,
  stakeFunc: PropTypes.func.isRequired,
  connectContractActionFunc: PropTypes.func.isRequired,
  contract: PropTypes.shape({
    userAddress: PropTypes.string,
    values: PropTypes.shape({
      contractAddress: PropTypes.string,
    }),
    error: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      address: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  stake: PropTypes.shape({
    variables: PropTypes.shape({
      pendingApprove: PropTypes.bool,
      pendingStake: PropTypes.bool,
      deployedStakingStart: PropTypes.number.isRequired,
      deployedStakingEnd: PropTypes.number.isRequired,
      rewardBalance: PropTypes.number.isRequired,
      stakedTotal: PropTypes.number,
      stakingCap: PropTypes.number.isRequired,
      allowance: PropTypes.number,
      balanceOf: PropTypes.number,
    }),
    complete: PropTypes.bool.isRequired,
    stakeTransactionId: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Stake);
