import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { TOKEN } from '../../utils/common';

class Home extends PureComponent {
  render() {
    return (
      <div className="min-component-height  white-text top-padding-home">
        <div className="center-content center component-height home-background section">
          <div className="row container">
            <h3 className="section">Festaking Contract</h3>
            <h5 className="section">
              Festaking is a flexible staking contract for ERC20 tokens.
            </h5>
            <div className="section container col s12">
              <p>
                Welcome to Ferrum Network staking. FeStaking is a flexible
                pool-based staking system that will secure the network and
                reward stakers based on how long they stake. FeStaking is
                unique.
              </p>
              <p>
                It enables multiple staking pools to be run at once, has no
                minimum, and has an option to receive rewards early. With short,
                medium and long term pools with different reward structures,
                there is an something for everyone.
              </p>
            </div>
          </div>
        </div>
        <div className="row section">
          <div
            className="col s12 "
            style={{ backgroundColor: '#112b3b', padding: '2rem' }}
          >
            <div className="col s12 m5">
              <div
                className="card z-depth-5"
                style={{ backgroundColor: '#1d4863' }}
              >
                <div className="card-content white-text">
                  <span className="card-title section">Recent Contracts</span>
                  <div>
                    <div className="collection black-text section grey lighten-3">
                      <div className="collection-item grey lighten-3">
                        <NavLink
                          className="a-contract  black-text hoverable"
                          to="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col s12 m7">
              <div
                className="card  z-depth-5"
                style={{ backgroundColor: '#1d4863' }}
              >
                <div className="card-content white-text">
                  <div className="section">
                    <span className="card-title \">How Festaking Works</span>
                    <br />
                    <p>
                      First, install MetaMask wallet on your browser and deposit
                      ERC-20 {TOKEN} into your wallet. Next, select a pool based on
                      your time preference and the reward structure. The pools
                      with longer staking periods have higher rewards, while
                      shorter staking have lower rewards. When the pool opens
                      up, send {TOKEN} to the address.
                    </p>
                    <br />
                    <p>
                      The pool will close when the deposit period ends or when
                      the pool is filled at which time staking begins. Next,
                      wait until maturity date or early withdrawal period opens.
                    </p>
                    <br />
                    <p>
                      Early withdrawals will receive a lesser reward and the
                      remainder will be distributed equally among the other pool
                      participants. Those
                      who wait until full maturity will receive the full amount.
                      Staking rewards are automatically sent to your wallet.
                    </p>
                    <br />
                  </div>
                  <br />
                  <div className="card-action space-btn section">
                    <NavLink to="/">Read More &gt;&gt; </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
