/* eslint-disable max-classes-per-file */
import FerrumJson from '../abi/FerrumToken.json';
import FestakingJson from '../abi/Festaking-abi.json';
import FestakingBytecode from '../abi/Festaking-bytecode.json';
import { TransactionMonitor } from './transactionMonitor';
import { Big } from 'big.js';

const festakingBytecode = FestakingBytecode.object;

export class ContractCallError extends Error {
  constructor(msg, error) {
    super(msg);
    this.error = error;
  }
}

class ContractBase {
  async init(web3, config, gasPriceProvider, transactionMonitor) {
    this.web3 = web3;
    this.config = config;
    this.gasPriceProvider = gasPriceProvider;
    this.transactionMonitor = transactionMonitor;
    this.callContractWrapper = this.callContractWrapper.bind(this);
  }

  sendTransactionParams(sender, gas) {
    return {
      from: sender, gas
      //, gasPrice: this.gasPriceProvider.getPriceWei(),
    };
  }

  async contractExist(contractAddress) {
    const code = await this.web3.eth.getCode(contractAddress);
    return code.length > 4;
  }

  /**
     * Calls a contract, receives transaction ID. Watches for transaction.
     * * fun should return a method transactions
     */
  async callContractWrapper(topic, gas, sender, fun) {
    try {
      const method = fun();
      const txId = await (new Promise((resolve, reject) => {
        method.send(this.sendTransactionParams(sender, gas))
          .on('transactionHash', (tid) => resolve(tid))
          .catch((e) => reject(e));
      }));
      if (!txId) {
        throw new ContractCallError(`Calling contract for '${topic}' returned no transaction ID`, undefined);
      }
      await this.transactionMonitor.monitor(topic, txId);
      return txId;
    } catch (e) {
      if (e instanceof ContractCallError) {
        throw e;
      }
      console.error(e);
      throw new ContractCallError(`Error calling contract method: ${topic}`, e);
    }
  }
}

export class TokenContract extends ContractBase {
  constructor() {
    super();
    this.rawToAmount = this.rawToAmount.bind(this);
    this.amountToRaw = this.amountToRaw.bind(this);
  }

  async init(web3, config, gasPriceProvider, transactionMonitor) {
    super.init(web3, config, gasPriceProvider, transactionMonitor);
    if (!await this.contractExist(config.tokenAddress)) {
      throw new ContractCallError(`Token contract '${config.tokenAddress}' not found. Make sure metamask is connected to the right network`, null);
    }
    this.frm = await new web3.eth.Contract(FerrumJson.abi, config.tokenAddress);
    this.tokenName = await this.frm.methods.name.call().call();
    this.symbol = await this.frm.methods.symbol.call().call();
    this.decimals = await this.frm.methods.decimals.call().call();
  }

  async balanceOf(address) {
    return this.rawToAmount(await this.frm.methods.balanceOf(address).call());
  }

  async allowance(userAddress, contractAddress) {
    return this.rawToAmount(await this.frm.methods.allowance(userAddress, contractAddress).call());
  }

  amountToRaw(amount) {
    return new Big(amount).mul(10 ** this.decimals).toFixed();
  }

  rawToAmount(raw) {
    return Number(new Big(raw).div(10 ** this.decimals).toFixed());
  }

  async approve(userAddress, target, amount) {
    const rawAmount = this.amountToRaw(amount);
    return this.callContractWrapper(TransactionMonitor.Topics.APPROVE,
      this.config.transactionGas.approve,
      userAddress,
      () => this.frm.methods.approve(target, rawAmount));
  }
}

export class FestakingContract extends ContractBase {
  constructor() {
    super();
    this.addReward = this.addReward.bind(this);
  }

  async init(web3, contractAddress, config, tokenContract, gasPriceProvider, transactionMonitor) {
    super.init(web3, config, gasPriceProvider, transactionMonitor);
    this.tokenContract = tokenContract;
    this.contractAddress = contractAddress;
    if (!await this.contractExist(contractAddress)) {
      throw new ContractCallError(`Staking contract '${contractAddress}' not found. Make sure metamask in connected to the right network`, null);
    }
    this.festaking = await new web3.eth.Contract(FestakingJson, contractAddress);
  }

  async parameters() {
    const rta = this.tokenContract.rawToAmount;
    const stakedTotal = rta(await this.festaking.methods.stakedTotal().call());
    const totalReward = rta(await this.festaking.methods.totalReward().call());
    const earlyWithdrawReward = rta(await this.festaking.methods.earlyWithdrawReward().call());
    const rewardBalance = rta(await this.festaking.methods.rewardBalance().call());
    const stakedBalance = rta(await this.festaking.methods.stakedBalance().call());
    const stakingCap = rta(await this.festaking.methods.stakingCap().call());
    console.log('TOTAL REW', totalReward, stakingCap, await this.festaking.methods.stakingCap().call());

    const deployedStakingStart = Number(await this.festaking.methods.stakingStarts().call());
    const deployedStakingEnd = Number(await this.festaking.methods.stakingEnds().call());
    const deployedWithdrawStart = Number(await this.festaking.methods.withdrawStarts().call());
    const deployedWithdrawEnd = Number(await this.festaking.methods.withdrawEnds().call());
    return {
      stakedTotal,
      totalReward,
      earlyWithdrawReward,
      rewardBalance,
      stakedBalance,
      deployedStakingStart,
      deployedStakingEnd,
      deployedWithdrawStart,
      deployedWithdrawEnd,
      stakingCap,
    };
  }

  async stakeOf(address) {
    return this.tokenContract.rawToAmount(await this.festaking.methods.stakeOf(address).call());
  }

  async stake(stakerAddress, amount) {
    const rawAmount = this.tokenContract.amountToRaw(amount);
    return await this.callContractWrapper(
      TransactionMonitor.Topics.STAKE,
      this.config.transactionGas.stake,
      stakerAddress,
      () => {
        console.log('ABOUT TO CALL STAKE ', amount, rawAmount)
        return this.festaking.methods.stake(rawAmount)
      },
    );
  }

  async withdraw(stakerAddress, amount) {
    const rawAmount = this.tokenContract.amountToRaw(amount);
    return await this.callContractWrapper(
      TransactionMonitor.Topics.WITHDRAW,
      this.config.transactionGas.stake,
      stakerAddress,
      () => this.festaking.methods.withdraw(rawAmount),
    );
  }

  async addReward(userAddress, rewardAmount, withdrawableAmount) {
    const rawRewardAmount = this.tokenContract.amountToRaw(rewardAmount);
    const rawWithdrawableAmount = this.tokenContract.amountToRaw(withdrawableAmount);
    await this.callContractWrapper(
      TransactionMonitor.Topics.ADD_REWARD,
      this.config.transactionGas.addReward,
      userAddress,
      () => this.festaking.methods.addReward(rawRewardAmount, rawWithdrawableAmount),
    );
  }
}

export const deploy = (web3, data, config) => new Promise(async (resolve, reject) => {
  const accounts = await web3.eth.getAccounts();
  const [ac1] = accounts;
  const owner = ac1;

  const GAS = config.transactionGas.deploy;
  let festaking;

  const {
    name,
    stakingStart,
    stakingEnd,
    withdrawStart,
    withdrawEnd,
    stakingCap,
  } = data;
  console.log('ABOUT TO CREATE CTR WITH DATA', data)
  try {
    const constructorArgs = [
      name,
      config.tokenAddress,
      stakingStart,
      stakingEnd,
      withdrawStart,
      withdrawEnd,
      new Big(stakingCap).mul(10**18).toFixed(),
    ];

    festaking = await new web3.eth.Contract(FestakingJson)
      .deploy({ data: `0x${festakingBytecode}`, arguments: constructorArgs, gasPrice: 1 }).send({ from: owner, gas: GAS });

    if (festaking._address) {
      alert(`Contract deployed with address: ${festaking._address}`);
    }
  } catch (error) {
    console.error(error);
    reject(error);
  }

  resolve({
    owner,
    contractAddress: festaking && festaking._address,
    ac1,
  });
});

export class Contracts {
    static Token = new TokenContract();

    static Festaking = new FestakingContract();
}
