
export async function getTransactionError(web3, transaction) {
    try {
        const code = await web3.eth.call(transaction);
        return hexToAscii(code.substr(138)).replace(/\0/g,'');
    } catch (e) {
        // Could not get the error
        return 'Unknwon error';
        // return 'Check etherscan for ' + transaction.hash.substr(0, 10) + '...';
    }
}


function hexToAscii(str1)
{
    var hex  = str1.toString();
    var str = '';
    for (var n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
}