/* eslint-disable react/prop-types */
import React from 'react';

const AddRewardForm = ({
  handleChange,
  handleSubmit,
  state,
  loading,
  disable,
}) => (
  <div className="container row">
    <div className="col s6 offset-s3">
      <form onSubmit={handleSubmit}>
        <div className="white-text">
          <label htmlFor="Reward Amount">Reward Amount</label>
          <input
            type="number"
            className="form-control"
            name="amount"
            aria-describedby="emailHelp"
            placeholder="Enter Reward Amount"
            onChange={handleChange}
            id="reward-amount"
            value={state.amount}
          />
        </div>
        <div className="white-text">
          <label htmlFor="Withdraw Amount">Withdraw Amount</label>
          <input
            type="number"
            className="form-control"
            name="withdrawable"
            placeholder="Enter Withdraw Amount"
            onChange={handleChange}
            id="withdrawable-amount"
            value={state.withdrawable}
          />
        </div>
        <br />
        <div className="center">
          <button
            type="submit"
            className="btn stepper-buttons"
            disabled={loading || disable}
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm stepper-buttons"
                  role="status"
                  aria-hidden="true"
                />
              Loading...
              </>
            ) : (
              'Add Reward'
            )}
          </button>
        </div>
      </form>
    </div>
  </div>
);

export default AddRewardForm;
