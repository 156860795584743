import React from 'react';
import PropTypes from 'prop-types';

const DisplayTime = ({ arg1, arg2 }) => (arg1 && arg2 ? (
    <div>
      <p>
        <span className="p-days-left">{arg1.value}</span>
        &nbsp;
        {arg1.name}
      </p>
      <p>
    &nbsp; and &nbsp;
        <b>{arg2.value}</b>
      &nbsp;
        <b>{arg2.name}</b>
      </p>
    </div>
  ) : (
    <p>Its Maturity Time</p>
  ));

DisplayTime.propTypes = {
  arg1: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  arg2: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
};
export default DisplayTime;
