import * as types from './types';
import { getWeb3, CONFIG, initializeWeb3 } from '../../utils/contracts';
import { errorToast, successToast } from '../../utils/toasts';
import { addStakeTransaction, vars } from './stake';
import { Contracts, deploy } from '../../utils/contractApi';
import { TransactionMonitor } from '../../utils/transactionMonitor';

export const addContractSuccessfull = (payload) => ({
  type: types.DEPLOY_CONTRACT_SUCCESSFUL,
  payload,
});

export const addUserAndContractAddress = (payload) => ({
  type: types.ADD_USER_AND_CONTRACT_ADDRESS,
  payload,
});

export const addContractFailed = (payload) => ({
  type: types.DEPLOY_CONTRACT_FAILED,
  payload,
});

export const contractLoading = (payload) => ({
  type: types.DEPLOY_CONTRACT_LOADING,
  payload,
});

export const valuesLoading = (payload) => ({
  type: types.DEPLOYMENT_VALUES_LOADING,
  payload,
});

export const valuesSuccess = (payload) => ({
  type: types.DEPLOYMENT_VALUES_SUCCESS,
  payload,
});

export const valuesError = (payload) => ({
  type: types.DEPLOYMENT_VALUES_ERROR,
  payload,
});

export const dispatchNetworkType = (payload) => ({
  type: types.DISPATCH_NETWORK_TYPE,
  payload,
});


export const deployContractAction = (data) => async (dispatch) => {
  dispatch(contractLoading(true));
  try {
    console.log('Pre deploy', data)
    const web3 = await getWeb3();
    await dispatch(dispatchNetworkType());
    const res = await deploy(web3, data, CONFIG);
    const deployedValues = await Contracts.Festaking.parameters();
    await dispatch(valuesSuccess({
      ...res,
      ...deployedValues,
      deployedStakingCap: deployedValues.stakingCap,
    }));
    successToast('contract deployed');
    document.getElementById('deployModal').click();
  } catch (error) {
    errorToast('Unable to deploy contract ');
    dispatch(valuesError(error));
  }
};

// Pick up from where we have left
const checkPendingStake = async (dispatch) => {
  const pending = Contracts.Festaking.transactionMonitor.pendingTransactions(TransactionMonitor.Topics.STAKE);
  if (pending && pending.length) {
    dispatch(addStakeTransaction(pending[0].txId));
  }
};

export const connectContractAction = (contractAddress) => async (dispatch) => {
  dispatch(contractLoading(true));
  try {
    const userAddress = await initializeWeb3(dispatch, contractAddress);
    const v = vars({ userAddress });
    await dispatch(v);
    await checkPendingStake(dispatch);
    dispatch(addContractSuccessfull({}));
  } catch (error) {
    console.error('Error connecting to contract', error);
    dispatch(addContractFailed(error.message));
  }
};
