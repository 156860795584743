import Web3 from 'web3';

export class GasPriceProvider {
    async init(web3) {
        const rawRes = await fetch('https://ethgasstation.info/json/ethgasAPI.json');
        const res = await rawRes.json();
        this.price = res.safeLow;
        this.wait = res.avgWait * 60;
        if (!this.price || !this.wait) {
            console.error('Error getting gas price', res);
            this.price = web3.utils.toWei('10', 'gwei');
            this.wait = 10 * 60; // 10 minutes
        }
        this.priceWei = Web3.utils.toWei(this.price.toString(), 'gwei');
    }

    getPriceWei() {
        return this.priceWei;
    }

    getWait() {
        return this.wait;
    }
}