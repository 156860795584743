import React from 'react';
import PropTypes from 'prop-types';
import WithdrawForm from '../../common/MultiStepForm';
import MultiStepCard from '../../common/MultiStepCard';
import Terms from '../../common/Terms';

const WithdrawStepper = ({
  amount,
  step,
  handleSubmit,
  handleChange,
  cancel,
  validateWithdraw,
  disable,
  acceptTerms,
  checked,
  nextStep,
}) => {
  switch (step) {
    case 0:
      return (
        <Terms acceptTerms={acceptTerms} checked={checked} nextStep={nextStep} />
      );
    case 1:
      return (
        <WithdrawForm
          nextStep={validateWithdraw}
          handleChange={handleChange}
          amount={amount}
          title="Withdraw"
          disable={disable}
          isWithdraw
        />
      );
    case 2:
      return (
        <MultiStepCard
          nextStep={handleSubmit}
          amount={amount}
          cancel={cancel}
          buttonName="Submit"
          message="Do you want to proceed with the withdraw of"
          title="Confirm Transaction"
          disable={disable}
          isWithdraw
        />
      );
    default:
      return (
        <div />
      );
  }
};

// WithdrawStepper.propTypes = {
//   amount: PropTypes.number.isRequired,
//   step: PropTypes.number.isRequired,
//   handleSubmit: PropTypes.func.isRequired,
//   handleChange: PropTypes.func.isRequired,
//   cancel: PropTypes.func.isRequired,
//   validateWithdraw: PropTypes.func.isRequired,
//   disable: PropTypes.func.isRequired,
//   acceptTerms: PropTypes.func.isRequired,
//   checked: PropTypes.func.isRequired,
//   nextStep: PropTypes.func.isRequired,
// };
//
export default WithdrawStepper;
