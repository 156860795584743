import React from 'react';
import logo from '../assets/images/logo.png';

const Header = () => (
  <div className="container" style={{ paddingBottom: '2rem' }}>
  </div>

);

export default Header;
