import React from 'react';
import { DashboardBox } from './DashboardBox';

const StakeDashboard = ({
  link,
  stakeOf,
  stakingCap,
  totalReward,
  earlyWithdrawReward,
  stakedTotal,
  contractAddress,
  balanceOf,
  deployedStakingEnd,
  deployedStakingStart,
  deployedWithdrawStart,
  deployedWithdrawEnd,
  owner,
  bgColor,
  title,
}) => {
  return (
    <DashboardBox {...{
      link,
      stakeOf,
      stakingCap,
      totalReward,
      earlyWithdrawReward,
      stakedTotal,
      contractAddress,
      balanceOf,
      deployedStakingEnd,
      deployedStakingStart,
      deployedWithdrawStart,
      deployedWithdrawEnd,
      owner,
      bgColor,
      title,
    }}
    mode={'stake'}
    />
  );
};

export default StakeDashboard;
