
export class AsyncLoop {
    static instance = new AsyncLoop();
    queue = [];
    dispatchScheduled = false;

    push(action) {
        this.queue.push(action);
        if (!this.dispatchScheduled) {
            setTimeout(() => this.dispatchAll());
        }
    }

    dispatchAll() {
        if (this.onAction) {
            const toDispatch = [...this.queue];
            toDispatch.forEach(a => this.onAction(a));
        }
    }

    registerOnAction(onAction) {
        this.onAction = onAction;
        return () => {this.onAction = undefined;}
    }
}