/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import DeployForm from '../components/DeployForm';
import { errorToast } from '../../utils/toasts';
import {
  deployContractAction,
} from '../../redux/actions/deploy';
import AddContractModal from '../components/AddContractModel';
import { formFormatter } from '../../utils/dateFormater';

const ONE_HOUR = 3600 * 1000;

export class Deploy extends Component {
  state = {
    name: 'Staking',
    stakingCap: 0,
    stakingStart: formFormatter(new Date()),
    stakingEnd: formFormatter(Date.parse(new Date()) + ONE_HOUR),
    withdrawStart: formFormatter(Date.parse(new Date()) + ONE_HOUR * 2),
    withdrawEnd: formFormatter(Date.parse(new Date()) + ONE_HOUR * 3),
  };

  componentDidMount() {
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      inDuration: 500, outDuration: 500, opacity: 0.6, dismissible: false,
    });

    // document.getElementById('deployModal').click();
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    console.log('ONCHANGE ', {value, name})
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { deployContractActionFunc } = this.props;
    const {
      name,
      stakingCap,
      stakingStart,
      stakingEnd,
      withdrawStart,
      withdrawEnd,
    } = this.state;
    const stakingStartSec = Date.parse(stakingStart) / 1000;
    const stakingEndSec = Date.parse(stakingEnd) / 1000;
    const withdrawStartSec = Date.parse(withdrawStart) / 1000;
    const withdrawEndSec = Date.parse(withdrawEnd) / 1000;

    const stakingData = {
      name,
      stakingCap,
      stakingStart: stakingStartSec,
      stakingEnd: stakingEndSec,
      withdrawStart: withdrawStartSec,
      withdrawEnd: withdrawEndSec,
    };

    if (stakingCap <= 0) {
      errorToast('Staking cap should be above zero');
      return;
    }
    if (stakingEndSec < stakingStartSec) {
      errorToast('Staking end must be after staking starts');
      return;
    }
    if (stakingEndSec > withdrawStartSec) {
      errorToast('Withdraw starts must be after staking ends');
      return;
    }
    if (withdrawEndSec < withdrawStartSec) {
      errorToast('withdrawEnds must be after withdraw starts');
      return;
    }
    console.log('About to deploy with ', stakingData)
    await deployContractActionFunc({ ...stakingData, stakingCap: stakingCap });
    this.setState({
      name: 'Staking',
      stakingCap: 0,
      stakingStart: Date.parse(new Date()) / 1000,
      stakingEnd: Date.parse(new Date()) / 1000,
      withdrawStart: Date.parse(new Date()) / 1000,
      withdrawEnd: Date.parse(new Date()) / 1000,
    });
  };

  render() {
    const {
      name,
      stakingCap,
      stakingStart,
      stakingEnd,
      withdrawStart,
      withdrawEnd,
      loading,
    } = this.state;

    const {
      contract: {
        values: {
          contractAddress,
          deployedStakingCap,
          deployedStakingStart,
          deployedStakingEnd,
          deployedWithdrawStart,
          deployedWithdrawEnd,
        },
      },
    } = this.props;
    return (
      <div className="container min-component-height section">
        <div data-target="modal1" id="deployModal" className="modal-trigger" />
        <AddContractModal
          contractAddress={contractAddress}
          name={name}
          stakingCap={deployedStakingCap}
          stakingStart={deployedStakingStart}
          stakingEnd={deployedStakingEnd}
          withdrawStart={deployedWithdrawStart}
          withdrawEnd={deployedWithdrawEnd}
        />
        <DeployForm
          name={name}
          stakingCap={stakingCap}
          stakingStart={stakingStart}
          stakingEnd={stakingEnd}
          withdrawStart={withdrawStart}
          withdrawEnd={withdrawEnd}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          loading={loading}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ contract }) => ({ contract });

const mapDispatchToProps = {
  deployContractActionFunc: deployContractAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Deploy);
