/* eslint-disable no-async-promise-executor */
import Web3 from 'web3';
import { GasPriceProvider } from './gasPriceProvider';
import { TransactionMonitor } from './transactionMonitor';
import {
  addUserAndContractAddress,
  dispatchNetworkType,
} from '../redux/actions/deploy';
import { Contracts } from './contractApi';

const IS_PROD = true;
const TOKEN_MAINNET_CONTRACT = '0x607c794cda77efb21f8848b7910ecf27451ae842';
export const TOKEN_ROBSTEN_CONTRACT = '0x42b4b07bb4909dc2eeef346dfaa098cb7da7bc44';

const TOKEN_PER_NET = {
  1: TOKEN_MAINNET_CONTRACT,
  3: TOKEN_ROBSTEN_CONTRACT,
}

const prodConfig = {
  tokenAddress: TOKEN_MAINNET_CONTRACT,
  network: 'prod',
  transactionGas: {
    approve: 46000,
    deploy: 2600000,
    stake: 200000,
    addReward: 200000,
  },
};

const ganacheConfig = {
  ...prodConfig,
  tokenAddress: '0xB954C097d20a51E681FB98258037Decb87Bf8Fa5',
  network: 'debug',
};

export const CONFIG = IS_PROD ? prodConfig : ganacheConfig;

export async function initializeWeb3(dispatch, contractAddress) {
  try {
    const web3 = await getWeb3();
    const networkType = await web3.eth.net.getNetworkType();
    const accounts = await web3.eth.getAccounts();
    const [userAddress] = accounts;
    const config = CONFIG;
    const gasPriceProvider = new GasPriceProvider();
    await gasPriceProvider.init(web3);
    const transactionMonitor = new TransactionMonitor(web3, dispatch, contractAddress);
    await Contracts.Token.init(web3, config, gasPriceProvider, transactionMonitor);
    await Contracts.Festaking.init(web3, contractAddress, config, Contracts.Token, gasPriceProvider, transactionMonitor);
    transactionMonitor.startMonitoring();
    dispatch(dispatchNetworkType(networkType));
    dispatch(addUserAndContractAddress({contractAddress, userAddress}));
    return userAddress;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const getWeb3 = async () => {
  let web3;

  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    const netId = await web3.eth.net.getId();
    prodConfig.tokenAddress = TOKEN_PER_NET[netId];
    return web3;
  } else if (window.web3) {
    web3 = new Web3(window.web3.currentProvider);
    const netId = await web3.eth.net.getId();
    prodConfig.tokenAddress = TOKEN_PER_NET[netId];
    return web3;
  } else {
    const error = 'Metamask Not Detected';
    throw error;
  }
};
