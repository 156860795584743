import React from 'react';
import PropTypes from 'prop-types';

const MultiStepCard = ({
  nextStep,
  cancel,
  amount,
  buttonName,
  message,
  title,
  disable,
}) => (
  <div className="">
    <div className="center center-content">
      <div>
        <br />
        <h5 className="">{title}</h5>
        <p>
          {message} <strong>{amount}</strong> PIE <br/> You must have ETH in your wallet for gas otherwise the stake will fail
        </p>
        <br />
      </div>
    </div>
    <div className="two-inline-buttons">
      <button
        type="button"
        className="btn stepper-buttons"
        onClick={nextStep}
        disabled={disable}
      >
        {buttonName}
      </button>
      {/*<button type="button" className="btn cancel-buttons" onClick={cancel}>*/}
      {/*    Cancel*/}
      {/*</button>*/}
    </div>
    <br />
  </div>
);
MultiStepCard.propTypes = {
  nextStep: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  buttonName: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disable: PropTypes.func.isRequired,
};
export default MultiStepCard;
