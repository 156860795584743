export const DEPLOY_CONTRACT_SUCCESSFUL = 'DEPLOY_CONTRACT_SUCCESSFUL';
export const DEPLOY_CONTRACT_FAILED = 'DEPLOY_CONTRACT_FAILED';
export const DEPLOY_CONTRACT_LOADING = 'DEPLOY_CONTRACT_LOADING';

export const DEPLOYMENT_VALUES_LOADING = 'DEPLOYMENT_VALUES_LOADING';
export const DEPLOYMENT_VALUES_ERROR = 'DEPLOYMENT_VALUES_ERROR';
export const DEPLOYMENT_VALUES_SUCCESS = 'DEPLOYMENT_VALUES_SUCCESS';
export const DISPATCH_NETWORK_TYPE = 'DISPATCH_NETWORK_TYPE';

export const ADD_REWARD_SUCCESSFULL = 'ADD_REWARD_SUCCESSFULL';
export const ADD_REWARD_FAILED = 'ADD_REWARD_FAILED';
export const ADD_REWARD_REQUEST = 'ADD_REWARD_REQUEST';

export const ADD_STAKE_SUCCESSFULL = 'ADD_STAKE_SUCCESSFULL';
export const ADD_STAKE_TRANSACTION = 'ADD_STAKE_TRANSACTION';
export const ADD_STAKE_FAILED = 'ADD_STAKE_FAILED';
export const ADD_STAKE_REQUEST = 'ADD_STAKE_REQUEST';
export const UPDATE_STAKE_VARIABLES = 'UPDATE_STAKE_VARIABLES';
export const APPROVE_STAKE_SUCCESSFULL = 'APPROVE_STAKE_SUCCESSFULL';
export const APPROVE_STAKE_FAILED = 'APPROVE_STAKE_FAILED';
export const UPDATE_ALLOWANCE = 'UPDATE_ALLOWANCE';

export const WITHDRAW_REQUEST = 'WITHDRAW_REQUEST';
export const ADD_WITHDRAW_TRANSACTION = 'ADD_WITHDRAW_TRANSACTION';
export const SET_WITHDRAW_TO_DEFAULT_STATE = 'SET_WITHDRAW_TO_DEFAULT_STATE';
export const ADD_WITHDRAW_FAILED = 'ADD_WITHDRAW_FAILED';

export const TRANSACTION_STATUS_CHANGED = 'TRANSACTION_STATUS_CHANGED';
export const TRANSACTION_LIST_UPDATED = 'TRANSACTION_LIST_UPDATED';

export const ADD_USER_AND_CONTRACT_ADDRESS = 'ADD_USER_AND_CONTRACT_ADDRESS';

export function addAction(type, payload) {
  return { type, payload };
}
