import React, { useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import StakeStepper from './StakeForm';
import Loader from '../../common/MultiStepFormLoader';
import AddressDetails from '../../common/AddressDetails';
import { QontoConnector, useStyles } from '../../utils/common';

function getSteps() {
  return ['Terms', 'Stake', 'Submit', 'Review'];
}

export default function StepperUi({
  amount,
  checked,
  handleSubmit,
  handleChange,
  cancel,
  validateStake,
  authorizeStake,
  disable,
  activeStep,
  acceptTerms,
  handleNext,
  loading,
  complete,
  stake,
  data,
  userAddress,
}) {
  const classes = useStyles();
  const steps = getSteps();
  const { balanceOf } = stake.variables;

  useEffect(() => {
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      inDuration: 500,
      outDuration: 500,
      opacity: 0.6,
      dismissible: false,
      endingTop: '20%',
    });
  }, []);

  return (
    <div className={`${classes.root} container `}>
      <div className="row">
        <div className="col s12 m2" />
        <div className="col s12 m8">
          <AddressDetails
            contractAddress={data && data.contractAddress}
            currentAddress={data && userAddress}
            message="stake"
            amount={balanceOf}
          />
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<QontoConnector />}
            classes={{ root: classes.root, completed: classes.completed }}
            style={{ background: 'linear-gradient( #ffffff, #DDDDDE)' }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    iconContainer: classes.new,
                    labelContainer: classes.labelContainer,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.new,
                      active: classes.active,
                      completed: classes.completed,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions} />
              </div>
            ) : (
              <Typography className={classes.instructions} component="span" variant="body2">
                {loading ? (
                  <Loader complete={complete} />
                ) : (
                  <div>
                    <StakeStepper
                      amount={amount}
                      step={activeStep}
                      handleSubmit={handleSubmit}
                      handleChange={handleChange}
                      cancel={cancel}
                      validateStake={validateStake}
                      authorizeStake={authorizeStake}
                      disable={disable}
                      acceptTerms={acceptTerms}
                      checked={checked}
                      nextStep={handleNext}
                    />
                  </div>
                )}
                <br />
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
StepperUi.propTypes = {
  userAddress: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  validateStake: PropTypes.func.isRequired,
  authorizeStake: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  acceptTerms: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  complete: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  data: PropTypes.shape({
    contractAddress: PropTypes.string,
    ac1: PropTypes.string,
  }),
  stake: PropTypes.shape({
    variables: PropTypes.shape({
      balanceOf: PropTypes.number,
    }),
  }).isRequired,
};

StepperUi.defaultProps = {
  error: null,
  data: null,
};
