import * as types from './types';
import { errorToast, successToast } from '../../utils/toasts';
import { calculateReward } from '../../utils/common';
import { Contracts } from '../../utils/contractApi';
import { TransactionMonitor } from '../../utils/transactionMonitor';

export const addStakeSuccessfull = (payload) => ({
  type: types.ADD_STAKE_SUCCESSFULL,
  payload,
});

export const addStakeTransaction = (payload) => ({
  type: types.ADD_STAKE_TRANSACTION,
  payload,
});

export const addStakeRequest = () => ({
  type: types.ADD_STAKE_REQUEST,
});

export const addStakeFailure = (payload) => ({
  type: types.ADD_STAKE_FAILED,
  payload,
});

export const approveStakeSuccesfull = () => ({
  type: types.APPROVE_STAKE_SUCCESSFULL,
});

export const approveStakeFailure = (payload) => ({
  type: types.APPROVE_STAKE_FAILED,
  payload,
});

export const updateStakeVariables = (payload) => ({
  type: types.UPDATE_STAKE_VARIABLES,
  payload,
});

export const updateAllowance = (allowance) => ({
  type: types.UPDATE_ALLOWANCE,
  payload: allowance,
});

export const authorizeAddStake = (amount, data, userAddress) => async (dispatch) => {
  const { contractAddress } = data;
  dispatch(addStakeRequest());
  console.log('authorizeAddStake', amount, data)
  let allowance = 0;
  try {
    allowance = await Contracts.Token.allowance(userAddress, Contracts.Festaking.contractAddress);
    console.log('allowance is ', allowance, !allowance)
    if (!allowance) {
      console.log('About to approve for amount.', amount);
      await Contracts.Token.approve(userAddress, contractAddress, amount);
      successToast('Approve transaction submitted, submitting stake...');
      console.log('Approve was successful, now doing some moew.')
    }
  } catch (error) {
    errorToast('Unable to approve the stake, try again');
    dispatch(approveStakeFailure());
    return;
  }
  try {
    const txId = await Contracts.Festaking.stake(userAddress, allowance || amount);
    await dispatch(vars({ ...data, userAddress }));
    dispatch(addStakeTransaction(txId));
    successToast('Stake transaction submitted');
  } catch (e) {
    console.error('Error calling stake', e);
    dispatch(addStakeFailure(e));
    errorToast('Error submitting the stake transaction');
  }
};

export const vars = (data) => async (dispatch) => {
  const {
    userAddress, calculateProfit, amount,
  } = data;


  const balanceOf = await Contracts.Token.balanceOf(userAddress);
  const stakeOf = await Contracts.Festaking.stakeOf(userAddress);
  const allowance = await Contracts.Token.allowance(userAddress, Contracts.Festaking.contractAddress);

  const pendingApprove = Contracts.Token.transactionMonitor.pendingTransactions(TransactionMonitor.Topics.APPROVE).length > 0;
  const pendingStake = Contracts.Token.transactionMonitor.pendingTransactions(TransactionMonitor.Topics.STAKE).length > 0;

  const contractParameters = await Contracts.Festaking.parameters();
  let profit = 0;


  if (calculateProfit) {
    const {
      deployedWithdrawEnd,
      earlyWithdrawReward,
      totalReward,
      deployedStakingEnd,
      stakedTotal,
    } = contractParameters;

    profit = calculateReward(
      amount,
      Date.parse(new Date()) / 1000,
      earlyWithdrawReward,
      totalReward,
      deployedWithdrawEnd,
      deployedStakingEnd,
      stakedTotal,
      0,
    );
  }
  console.log('GOT VARS',
    {
      ...contractParameters,
      balanceOf,
      stakeOf,
      allowance,
      profit,
      pendingApprove,
      pendingStake,

    }
    )

  dispatch(
    updateStakeVariables({
      ...contractParameters,
      balanceOf,
      stakeOf,
      allowance,
      profit,
      pendingApprove,
      pendingStake,
    }),
  );
};

