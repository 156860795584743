/* eslint-disable import/prefer-default-export */
import { transactionStatusChanged } from '../redux/actions/transactionMonitor';
import { getTransactionError } from './ethErrorHandler';
import { addAction, TRANSACTION_LIST_UPDATED } from '../redux/actions/types';

const TRANSCTION_TIMEOUT = 3600 * 1000;

function isSubmittedTimedOut(time) {
  return Date.now() - time > TRANSCTION_TIMEOUT;
}

export class TransactionMonitor {
    static KEY = 'TRANSACTION_MONITORS';

    static MONITOR_REPREAT_PERIOD = 5 * 1000;

    // 15 seconds
    static Topics = {
      APPROVE: 'APPROVE',
      STAKE: 'STAKE',
      WITHDRAW: 'WITHDRAW',
      ADD_REWARD: 'ADD_REWARD',
    };

    static States = {
      TIMED_OUT: 'TIMED_OUT',
      SUBMITTED: 'SUBMITTED',
      PENDING: 'PENDING',
      CONFIRMED: 'CONFIRMED',
      FAILED: 'FAILED',
    };

    constructor(web3, dispatch, contractAddress) {
      this.web3 = web3;
      this.dispatch = dispatch;
      this.contractAddress = contractAddress;
      if (!contractAddress) {
        throw new Error('contractAddress must be provided');
      }
      this.startMonitoring();
      try {
        this.transactions = JSON.parse(localStorage.getItem(this.storageKey()) || '{}');
        this.dispatch(addAction(TRANSACTION_LIST_UPDATED, this.transactions));
      } catch (e) {

      }
    }

    transactions = {};

    storageKey() {
      return `${TransactionMonitor.KEY}/${this.contractAddress}`;
    }

    pendingTransactions(topic) {
      return (this.transactions[topic] || []).filter(
        (t) => t.lastState.state === TransactionMonitor.States.PENDING
                || t.lastState.state === TransactionMonitor.States.SUBMITTED,
      );
    }

    findTransactionById(topic, txId) {
      if (!txId) { return; }
      return (this.transactions[topic] || []).find((t) => t.txId === txId);
    }

    async monitor(topic, txId) {
      const initState = { state: TransactionMonitor.States.SUBMITTED, creationTime: Date.now() };
      const txResult = await this.getTransactionById(txId);
      if (txResult) {
        const { confirmed, failed, reason } = txResult;
        initState.state = confirmed ? TransactionMonitor.States.CONFIRMED
          : failed ? TransactionMonitor.States.FAILED
            : TransactionMonitor.States.PENDING;
        initState.reason = reason;
      }
      this.transactions[topic] = (this.transactions[topic] || []).concat([{
        txId,
        lastState: initState,
      }]);
      this.save();
    }

    save() {
      localStorage.setItem(this.storageKey(), JSON.stringify(this.transactions));
      this.dispatch(addAction(TRANSACTION_LIST_UPDATED, this.transactions));
    }

    async getTransactionById(txId) {
      // Get the transaction, and the receipt. Check the status.
      const tx = await this.web3.eth.getTransaction(txId);
      if (tx) {
        const receipt = await this.web3.eth.getTransactionReceipt(txId);
        if (!receipt || !receipt.blockNumber) {
          return { confirmed: false, failed: false };
        }
        if (receipt.status) {
          return { confirmed: true, failed: false };
        }
        // Failed get reason
        const reason = await getTransactionError(this.web3, tx);
        return { confirmed: false, failed: true, reason };
      }
    }

    startMonitoring() {
      const cycle = async () => {
        const txs = { ...this.transactions };
        let changed = false;
        for (const topic of Object.keys(txs)) {
          for (const tx of txs[topic]) {
            const { txId, lastState } = tx;
            const { state, creationTime } = lastState;
            if (state === TransactionMonitor.States.TIMED_OUT
                        || state === TransactionMonitor.States.CONFIRMED
                        || state === TransactionMonitor.States.FAILED) {
              continue;
            }
            if (isSubmittedTimedOut(creationTime)) {
              // Timed out, not checking any more.
              tx.lastState.state = TransactionMonitor.States.TIMED_OUT;
              changed = true;
              this.dispatch(transactionStatusChanged(topic));
            } else {
              const txResult = await this.getTransactionById(txId);
              if (txResult) {
                changed = true;
                const { confirmed, failed, reason } = txResult;
                tx.lastState.state = confirmed ? TransactionMonitor.States.CONFIRMED
                  : failed ? TransactionMonitor.States.FAILED
                    : TransactionMonitor.States.PENDING;
                tx.lastState.reason = reason;
                this.dispatch(transactionStatusChanged(topic));
              }
            }
          }
        }
        if (changed) {
          this.save();
        }
      };
      setInterval(cycle, TransactionMonitor.MONITOR_REPREAT_PERIOD);
    }
}
