import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { calculateReward } from '../../utils/common';
import { errorToast } from '../../utils/toasts';
import { connectContractAction } from '../../redux/actions/deploy';
import { TOKEN } from '../../utils/common';

const RewardCalculator = ({
  contract,
  stake,
  connectContractActionFunc,
  match,
}) => {
  const {
    params: { address },
  } = match;
  useEffect(() => {
    connectContractActionFunc(address);
  }, []);
  const [sliderVal, setSliderVal] = useState(0);

  const {
    variables: {
      totalReward,
      earlyWithdrawReward,
      deployedWithdrawEnd,
      deployedStakingEnd,
      deployedWithdrawStart,
      stakingCap,
    },
  } = stake;

  const { values: data, userAddress } = contract;

  const dateString = (date) => `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}T${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;

  const [reward, setReward] = useState(0);
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setDate(dateString(new Date(deployedWithdrawStart * 1000 + 1)));
  }, [deployedWithdrawStart]);

  useEffect(() => {
    const convertedDate = Date.parse(date) / 1000;
    const returnedReward = calculateReward(
      amount,
      convertedDate,
      earlyWithdrawReward,
      totalReward,
      deployedWithdrawEnd,
      deployedStakingEnd,
      stakingCap,
      sliderVal,
    );
    setReward(returnedReward);
  }, [amount, date, sliderVal]);

  const onChangeAmount = (event) => {
    if (totalReward === 0) {
      errorToast('Maturity & Early Withdraw Rewards have not been set yet');
      return;
    }
    const amount = Number(event.target.value);
    if (amount > stakingCap) {
      errorToast(`Staking cap is ${stakingCap} ${TOKEN}`);
      return;
    }
    setAmount(amount);
  };

  const onChangeDate = (event) => {
    if (
      Date.parse(new Date(event.target.value))
      < (deployedWithdrawStart * 1000)
    ) {
      errorToast(`Earliest unstake date is ${  new Date(deployedWithdrawStart * 1000).toLocaleString()}`);
    } else {
      setDate(event.target.value);
    }
  };

  const onChangeSlider = (event) => {
    setSliderVal(event.target.value);
  };

  const sliderEnabled = Date.parse(new Date(date)) >= deployedWithdrawEnd * 1000;

  return (

    <div className="min-component-height black-text card-row">
      <div className="row">
        <div className="col s12 m3" />
        <div className="col s12 m6">
          <div className="card card-bg-platinum">
            <div className="card-content black-text">
              <span className="card-title center section"><strong>Reward Calculator</strong></span>
              <table className="centered responsive-table">
                <tbody>
                  <tr>
                    <td>Your Address:</td>
                    <td>{userAddress}</td>
                  </tr>
                  <tr>
                    <td>Contract Address:</td>
                    <td>{data && data.contractAddress}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="card card-bg-platinum">
            <div className="card-content">
              <br />
              <form>
                <div className="row">
                  <div className="input-field col s12 m2" />
                  <div className="input-field col s12 m8">
                    <input
                      className="black-text mobile-friendly"
                      type="number"
                      name="stakedAmount"
                      id="stakedAmount"
                      required
                      value={amount || ''}
                      onChange={onChangeAmount}
                      placeholder={amount}
                    />
                    <label className="active black-text inline-labels" htmlFor="Stake">Staked {TOKEN}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12 m2" />
                  <div className="input-field col s12 m8">
                    <input
                      className="black-text datepicker mobile-friendly"
                      type="datetime-local"
                      id="unstakedDate"
                      name="unstakedDate"
                      onChange={onChangeDate}
                      required
                      value={date}
                      placeholder={date}
                    />
                    <label className="active black-text inline-labels" htmlFor="Unstaking">Unstaking Date</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12 m2" />
                  <div className="input-field col s12 m8">
                    <span className="active black-text inline-labels" htmlFor="Slider">Percentage of early unstake by others</span>
                    <input
                      className="black-text mobile-friendly"
                      type="range"
min="1"
max="100"
value={sliderVal}
                      onChange={onChangeSlider}
                      disabled={!sliderEnabled}
                      style={{ opacity: sliderEnabled ? 1 : 0.3 }}
                    />
                  </div>
                </div>
              </form>
              <p className="center section">
Rewards: &nbsp;&nbsp;&nbsp;

                <span>{reward.toFixed(4)}</span>
              </p>

              <div className="section center-content centered">
                <NavLink
                  className="btn btn-primary centered card-buttons"
                  to={`/dashboard/${address}`}
                >
                       Close
                </NavLink>
              </div>

              <div>
                <p className="">
                  ‣ All dates after maturity will produce
                       the same amount of reward. 
{' '}
<br />
                  ‣ When people unstake early, you (that unstaked after maturity) will get their share of maturity reward.
                </p>
                <p className="">
                  <span>
<small>‣ <b>Disclaimer:</b> The rewards calculator is an estimate and may not reflect actual rewards. It is based on certain
                  assumptions that might be different at time of staking. Participants are not entitled to any difference
                    between the rewards calculator and actual amount received.</small>

                  </span>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  connectContractActionFunc: connectContractAction,
};

const mapStateToProps = ({ contract, stake }) => ({ contract, stake });

RewardCalculator.propTypes = {
  connectContractActionFunc: PropTypes.func.isRequired,
  stake: PropTypes.shape({
    variables: PropTypes.shape({
      totalReward: PropTypes.number.isRequired,
      earlyWithdrawReward: PropTypes.number.isRequired,
      deployedWithdrawEnd: PropTypes.number.isRequired,
      deployedStakingEnd: PropTypes.number.isRequired,
      deployedWithdrawStart: PropTypes.number.isRequired,
      stakingCap: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      address: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  contract: PropTypes.shape({
    data: PropTypes.shape({
      owner: PropTypes.string.isRequired,
      contractAddress: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RewardCalculator);
