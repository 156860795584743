import React, { useEffect } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import M from 'materialize-css';
import WithdrawStepper from './WithdrawForm';
import Loader from '../../common/MultiStepFormLoader';
import AddressDetails from '../../common/AddressDetails';
import { QontoConnector, useStyles } from '../../utils/common';

function getSteps() {
  return ['Terms & Conditions', 'Withdraw', 'Confirm Transaction', 'Summary'];
}

export default function StepperWithdraw({
  amount,
  handleSubmit,
  handleChange,
  cancel,
  disable,
  activeStep,
  handleNext,
  loading,
  validateWithdraw,
  complete,
  acceptTerms,
  checked,
  data,
  stake,
}) {
  const classes = useStyles();
  const steps = getSteps();
  const { stakeOf } = stake.variables;

  useEffect(() => {
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      inDuration: 500,
      outDuration: 500,
      opacity: 0.6,
      dismissible: false,
      endingTop: '20%',
    });
  }, []);

  return (
    <div className={`${classes.root} container `}>
      <div className="row">
        <div className="col s12 m2" />
        <div className="col s12 m8">
          <AddressDetails
            contractAddress={data && data.contractAddress}
            currentAddress={data && data.ac1}
            message="withdraw"
            amount={stakeOf}
          />
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<QontoConnector />}
            classes={{ root: classes.root, }}
            style={{ background: 'linear-gradient( #ffffff, #DDDDDE)' }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    iconContainer: classes.new,
                    labelContainer: classes.labelContainer,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.new,
                      active: classes.active,
                      completed: classes.completed,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                {/*<Typography className={classes.instructions} />*/}
              </div>
            ) : (
              <div>
                {loading ? (
                  <Loader complete={complete} />
                ) : (
                    <WithdrawStepper
                      amount={amount}
                      step={activeStep}
                      handleSubmit={handleSubmit}
                      handleChange={handleChange}
                      cancel={cancel}
                      validateWithdraw={validateWithdraw}
                      disable={disable}
                      acceptTerms={acceptTerms}
                      checked={checked}
                      nextStep={handleNext}
                    />
                )}
                <br />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

// StepperWithdraw.propTypes = {
//   amount: PropTypes.number.isRequired,
//   handleSubmit: PropTypes.func.isRequired,
//   handleChange: PropTypes.func.isRequired,
//   cancel: PropTypes.func.isRequired,
//   disable: PropTypes.bool.isRequired,
//   activeStep: PropTypes.number.isRequired,
//   handleNext: PropTypes.func.isRequired,
//   loading: PropTypes.bool.isRequired,
//   validateWithdraw: PropTypes.func.isRequired,
//   complete: PropTypes.bool.isRequired,
//   acceptTerms: PropTypes.bool.isRequired,
//   checked: PropTypes.bool.isRequired,
//   data: PropTypes.objectOf.isRequired,
//   stake: PropTypes.objectOf.isRequired,
// };
