import React from 'react';
import PropTypes from 'prop-types';
import { StepDetails } from './StepDetails';
import { TOKEN } from '../utils/common';

const withdrawDetails = (
  <div> </div>
);

const stakeDetails = (
  <>
    You will be notified of two separate transactions in the next two steps:
    <ul>
      <li>
‣ First transaction is the
        {' '}
        <i>approve</i>
        {' '}
step,
        where you allow this staking contract to access to your tokens upto the provided amount.
        {' '}

      </li>
      <li>
‣ Second transaction is the
        {' '}
        <i>stake</i>
        {' '}
step,
        where the provided amount of tokens will actually be staked in the contract.
      </li>
    </ul>
  </>
);

const MultiStepForm = ({
  handleChange,
  amount,
  nextStep,
  disable = false,
  isWithdraw = false,
}) => (
  <form className="col s12">
    <br />
    <div className="row">
      <div className="input-field col s12">
        <input
          name="amount"
          type="number"
          id="amount-input"
          className="white-text mobile-friendly"
          onChange={handleChange}
          value={amount}
        />
        <label className="active white-text inline-labels" htmlFor="Amount">Amount in {TOKEN}</label>
      </div>

      <div className="right-align col s12">
        <button
          type="button"
          className="btn stepper-buttons"
          onClick={nextStep}
          disabled={disable}
        >
          Continue
        </button>
      </div>
    </div>
    <StepDetails>
      {' '}
      { isWithdraw ? withdrawDetails : stakeDetails }
      {' '}
    </StepDetails>
  </form>
);
// MultiStepForm.propTypes = {
//   handleChange: PropTypes.func.isRequired,
//   amount: PropTypes.number.isRequired,
//   nextStep: PropTypes.func.isRequired,
//   title: PropTypes.string.isRequired,
//   disable: PropTypes.bool.isRequired,
// };
//
export default MultiStepForm;
