/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Notification = ({ network }) => {
  const close = () => document.getElementById('close-alert').parentElement.remove();
  return (
    <div
      className={`alert alert-warning  ${
        network === 'main' ? 'light-blue' : 'orange'
      } darken-3`}
      role="alert"
    >
      <button
        type="button"
        className="close-alert white-text"
        id="close-alert"
        onClick={() => close()}
      >
        ×
      </button>
      <p className="white-text section">
        {network === 'main'
          ? `You are connected to ${network} Network`
          : network
            ? `You are connected to ${network} network, Make sure your connected to the Ethereum Main Network`
            : `Unable to detect Metamask in your browser, make sure you have metamask extension installed and enabled.
if it is installed and enable please refresh the page`}
      </p>
    </div>
  );
};

Notification.propTypes = {
  network: PropTypes.string.isRequired,
};

export default Notification;
