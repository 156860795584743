import React from 'react';
import HomeButton from './HomeButton';
import { TOKEN } from '../utils/common';

const SuccessBox = ({ amount, reward, withdraw }) => (
  <div
    id="modal3"
    className="modal default-bg-extended remove-border-outline success-modal"
    style={{ border: 'white solid 2px' }}
  >
    <div className="modal-content center-content no-bottom-margin white-text">
      <div className="text-center">
        {withdraw
          ? (
            <>
              <h4 className="section">Congratulations</h4>
              <h5>
You Received a &nbsp;
                <span style={{ color: '#ffa726' }}>
                  <strong>
                    {reward}
&nbsp;
                  {TOKEN}
                  </strong>
                </span>
&nbsp;Reward
              </h5>
              <h6 className="label section">
Total Amount Withdrawn:
                <span style={{ color: '#ffa726' }}>
                  <strong>
                  &nbsp;
                    {(Number(reward) + Number(amount)).toFixed(4)}
&nbsp;
                  {TOKEN}
                  </strong>

                </span>
              </h6>
            </>
          )
          : (
            <>
              <h4 className="section">Your stake was successful</h4>
              <h5>
      Amount Staked&nbsp;
                <span style={{ color: '#ffa726' }}>
                  <strong>
                    {amount}
&nbsp;{TOKEN}
                  </strong>

                </span>
              </h5>
              <h6 className="section">You can stake more in the same contract or any other contracts you choose.</h6>
            </>
          )}
        <HomeButton />
      </div>
    </div>
  </div>
);

export default SuccessBox;
