import { combineReducers } from 'redux';
import addRewardReducer from './addReward';
import withdrawReducer from './withdraw';
import stakeReducer from './stake';
import deployContractReducer from './deployContract';
import {transactionListReducer} from "./TransactionList";

const reducer = combineReducers({
  reward: addRewardReducer,
  withdraw: withdrawReducer,
  stake: stakeReducer,
  contract: deployContractReducer,
  transactionList: transactionListReducer,
});

export default reducer;
