/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import StepperWithdraw from '../components/StepperWithdraw';
import { connectContractAction } from '../../redux/actions/deploy';
import { vars } from '../../redux/actions/stake';
import { withdrawAction, withdrawToDefaultState } from '../../redux/actions/withdraw';
import { errorToast } from '../../utils/toasts';
import NotFound from '../../common/NotFound';
import SuccessBox from '../../common/SuccessBox';
import Error from '../../common/Error';
import TransactionList from './TransactionList';

export class WithDraw extends Component {
  state = {
    amount: 0,
    step: 1,
    disable: false,
    activeStep: 0,
    checked: false,
  };

  async componentDidMount() {
    const {
      connectContractActionFunc,
      contract,
      match: {
        params: { address },
      },
    } = this.props;
    if (!contract.data) {
      await connectContractActionFunc(address);
    }
    const {
      contract: { error },
    } = this.props;
    !error && this.disableButton();

    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      inDuration: 500,
      outDuration: 500,
      opacity: 0.8,
      dismissible: false,
      endingTop: '15%',
    });
  }

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  acceptTerms = (event) => {
    const { checked } = event.target;
    if (checked) {
      this.setState({ checked: true });
    } else {
      this.setState({ checked: false });
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  handleReset = () => {
    this.setState({
      amount: '',
      step: 1,
      disable: false,
      activeStep: 0,
      checked: false,
    });
  };

  disableButton = async () => {
    const {
      stake: {
        variables: { deployedWithdrawStart, stakeOf },
      },
    } = this.props;

    const currentDate = new Date();
    const now = currentDate.getTime();

    (now < deployedWithdrawStart * 1000 || stakeOf < 0.001)
      && this.setState({ disable: true });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validateWithdraw = async (event) => {
    event.preventDefault();
    const {
      stake: {
        variables: { stakeOf },
      },
    } = this.props;
    const { amount } = this.state;

    if (!amount || amount <= 0) {
      errorToast('Please enter a value above zero');
      return;
    }

    if (parseFloat(stakeOf) < parseFloat(amount)) {
      errorToast("You don't have enough funds");
      return;
    }
    this.handleNext();
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      contract: { values: data, userAddress },
      withdrawFunc,
    } = this.props;
    const { amount } = this.state;
    await withdrawFunc(
      amount,
      data,
      userAddress,
    );
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 2 });
  };

  render() {
    const {
      amount, step, activeStep, checked, disable,
    } = this.state;
    const {
      contract: { error, values, userAddress },
      stake,
      withdraw,
    } = this.props;
    return (
      <>
        {error ? (
          <NotFound />
        ) : (
          <>
            <div data-target="modal4" id="errorModal" className="modal-trigger" />
            <div data-target="modal3" id="successModal" className="modal-trigger" />
            <SuccessBox
              withdraw
              amount={amount}
              reward={stake.variables.profit.toFixed(4)}
            />
            <StepperWithdraw
              amount={amount}
              step={step}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              cancel={this.handleReset}
              disable={disable}
              activeStep={activeStep}
              handleBack={this.handleBack}
              handleNext={this.handleNext}
              loading={withdraw.loading}
              error={withdraw.error}
              validateWithdraw={this.validateWithdraw}
              complete={false}
              acceptTerms={this.acceptTerms}
              checked={checked}
              stake={stake}
              data={{ ...values, ac1: userAddress }}
            />
            <TransactionList />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ stake, withdraw, contract }) => ({
  contract,
  stake,
  withdraw,
});

const mapDispatchToProps = {
  connectContractActionFunc: connectContractAction,
  withdrawFunc: withdrawAction,
  varsFunc: vars,
  withdrawToDefaultStateFunc: withdrawToDefaultState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithDraw);
