import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const NotFound = ({ minPage, message }) => (
  <div className={`center-content center ${minPage ? 'component-height' : 'min-component-height'}`}>
    <div>
      <h1 className="white-text">{message}</h1>
      <br />
      <NavLink to="/" className="btn btn-primary">
        Go Back
      </NavLink>
    </div>
  </div>
);
NotFound.propTypes = {
  message: PropTypes.string,
  minPage: PropTypes.bool,
};

NotFound.defaultProps = {
  minPage: false,
  message: 'Oooops! Page Not Found',
};

export default NotFound;
