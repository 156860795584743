import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Router, Switch, Route } from 'react-router-dom';
import history from './utils/history';
import AddReward from './admin/containers/Rewards';
import WithDraw from './main/containers/WithDraw';
import Stake from './main/containers/Stake';
import Deploy from './admin/containers/Deploy';
import NotFound from './common/NotFound';
import Home from './main/containers/Home';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/home.scss';
import './assets/css/loader.scss';
import Loader from './common/Loader';
import Dashboard from './main/containers/Dashboard';
import Header from './common/Header';
import Notification from './main/components/Notification';
import RewardCalculator from './main/containers/RewardCalculator';
import MaturityTimer from './main/components/DisplayTime';
import DetectMetamask from './common/DetectMetamask';
import { AsyncLoop } from './redux/actions/asyncLoop';
import { connect } from 'react-redux';
import Footer from './common/Footer';

toast.configure({
  autoClose: 4000,
  draggable: false,
  position: toast.POSITION.TOP_RIGHT,
});

const AppComponent = ({ onAction }) => {
  useEffect(() => AsyncLoop.instance.registerOnAction(onAction));
  return (
    <Router history={history}>
      <Header />
      <Loader />
      <DetectMetamask />
      <ToastContainer />
      <Switch>
        <Home exact path="/" component={Home} />
        <Route exact path="/admin/:address/addReward" component={AddReward} />
        <Route exact path="/:address/withdraw" component={WithDraw} />
        <Route exact path="/:address/stake" component={Stake} />
        <Route exact path="/admin/deploy" component={Deploy} />
        <Route exact path="/dashboard/:address" component={Dashboard} />
        <Route exact path="/notification" component={Notification} />
        <Route
          exact
          path="/reward-calculator/:address"
          component={RewardCalculator}
        />
        <Route exact path="/MaturityTimer" component={MaturityTimer} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </Router>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onAction: (a) => dispatch(a),
});

export default connect(
  () => ({}),
  mapDispatchToProps,
)(AppComponent);
