/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
}));

const DeployForm = ({
  name,
  stakingCap,
  stakingStart,
  stakingEnd,
  withdrawStart,
  withdrawEnd,
  handleChange,
  handleSubmit,
  loading,
}) => {
  const classes = useStyles();
  return (
    <div className="container">
      <div className="row">
        <div className="col s6 offset-s3 ">
          <form onSubmit={handleSubmit} className={classes.container}>
            <div className=" white-text">
              <label htmlFor="Name">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Enter Staking Name"
                onChange={handleChange}
                id="name"
                value={name}
                required
              />
            </div>

            <div className=" white-text">
              <label htmlFor="Staking Cap">Staking Cap</label>
              <input
                type="number"
                className="form-control"
                name="stakingCap"
                placeholder="Enter Staking Cap"
                onChange={handleChange}
                id="stakingCap"
                value={stakingCap}
                required
              />
            </div>

            <div className=" white-text">
              <label htmlFor="Staking Start">Staking Start Date</label>
              <input
                type="datetime-local"
                className={classes.textField}
                id="stakingStart"
                name="stakingStart"
                onChange={handleChange}
                value={stakingStart}
                required
              />
            </div>

            <div className=" white-text">
              <label htmlFor="Staking End">Staking End Date</label>
              <input
                type="datetime-local"
                className={classes.textField}
                id="stakingEnd"
                name="stakingEnd"
                onChange={handleChange}
                value={stakingEnd}
                required
              />
            </div>

            <div className=" white-text">
              <label htmlFor="Withdraw Start">Withdraw Start Date</label>
              <input
                type="datetime-local"
                className={classes.textField}
                id="withdrawStart"
                name="withdrawStart"
                onChange={handleChange}
                value={withdrawStart}
                required
              />
            </div>

            <div className=" white-text">
              <label htmlFor="Withdraw End">Withdraw End Date</label>
              <input
                type="datetime-local"
                className={classes.textField}
                id="withdrawEnd"
                name="withdrawEnd"
                onChange={handleChange}
                value={withdrawEnd}
                required
              />
            </div>

            <div className="form-group text-center section center">
              <button
                type="submit"
                className="btn stepper-buttons"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </>
                ) : (
                  'Deploy Contract'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeployForm;
