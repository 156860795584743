/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddRewardForm from '../components/AddRewardForm';
import { addRewardAction } from '../../redux/actions/addReward';
import { errorToast } from '../../utils/toasts';
import { connectContractAction } from '../../redux/actions/deploy';
import { vars } from '../../redux/actions/stake';
import Error from "../../common/Error";

export class AddReward extends Component {
  state = { amount: 0, withdrawable: 0, disable: false };

  async componentDidMount() {
    const {
      connectContractFunc,
      contract: { data },
      match: {
        params: { address },
      },
    } = this.props;
    if (!data) {
      await connectContractFunc(address);
    }
    const {
      contract: { error },
    } = this.props;
    !error && this.disableButton();
  }

  disableButton = async () => {
    const {
      stake: {
        variables: { deployedStakingEnd },
      },
    } = this.props;
    const currentDate = new Date();
    const now = currentDate.getTime();

    now > deployedStakingEnd * 1000 && this.setState({ disable: true });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { amount, withdrawable } = this.state;
    const {
      contract: { userAddress, values: data },
      addReward,
    } = this.props;

    if (parseInt(amount, 10) <= 0) {
      errorToast('Please enter values above zero');
      return;
    }
    if (parseInt(withdrawable, 10) > parseInt(amount, 10)) {
      errorToast(
        'Withdrawable amount should be equal or less than reward amount',
      );
      return;
    }

    await addReward(
      amount,
      withdrawable,
      userAddress,
      data,
    );
    this.setState({ amount: 0, withdrawable: 0 });
  };

  render() {
    const {
      reward: { loading },
      contract: { error },
    } = this.props;
    const { disable } = this.state;
    return (
      <div className="min-component-height top-padding">
        {error ? (
          <Error message={error} />
        ) : (
          <>
            <br />
            <br />
            <AddRewardForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              state={this.state}
              loading={loading}
              disable={disable}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ contract, reward, stake }) => ({
  contract,
  reward,
  stake,
});

const mapDispatchToProps = {
  connectContractFunc: connectContractAction,
  addReward: addRewardAction,
  varsFunc: vars,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddReward);
